import React, { useMemo, useState } from 'react'
import Blockies from 'react-blockies';
import { isMobile } from 'react-device-detect';
import { Button, Typography, message, List, Tooltip, Spin, Row, Col } from 'antd'
import { BellFilled, WalletOutlined, CheckOutlined, CopyOutlined } from '@ant-design/icons'
import useSWR from 'swr'
import { useSnapshot } from 'valtio'

import styles from '../../index.less'
import SubWalletService from '../../../../network/subwallet'
import assetStore from '../../../../store/asset'
import { serialize } from '../../../../network/request'
import ProjectService from '../../../../network/project'
import { useInterval, useToggle } from 'react-use'

import Icon from '@ant-design/icons';
import { ReactComponent as notificationFailedIcon } from "../../../../notificationFailed.svg";
import { ReactComponent as notificationSuccessIcon } from "../../../../notificationSuccess.svg";
const NotiFailedIcon = () => <Icon component={notificationFailedIcon} />
const NotiSuccessIcon = () => <Icon component={notificationSuccessIcon} />


const SendButton = ({ value, status, isNotified, mutate }) => {
  const [isLoading, toggleLoading] = useToggle(false)

  const resendWebhook = async (hash) => {
    toggleLoading()
    try {
      const { data } = await ProjectService.resendWebhook({
        hash
      })

      message.success(
        data.messageCode === 'CALLBACK_RECEIVED' ? 'Response 200 OK received' : data.messageCode
      )

      mutate()
      toggleLoading()
    } catch (error) {
      message.error(error?.message)
      toggleLoading()
    }
  }

  return (
    <div className={styles.send}>
      {isNotified ? (
        <Tooltip title="Notify successful, click to resend">
          {isLoading ? (
            <Spin />
          ) : (
            <Button type="ghost" shape="circle" icon={<NotiSuccessIcon />} size="middle"
              onClick={() => {
                resendWebhook(value)
              }}
              ghost />
          )}
        </Tooltip>
      ) : (
        <Tooltip title="Your application did not respond to the notify callback, click to resend">
          {isLoading ? (
            <Spin />
          ) : (
            <Button type="ghost" shape="circle" icon={<NotiFailedIcon />} size="middle"
              onClick={() => {
                resendWebhook(value)
              }}
              ghost />
          )}
        </Tooltip>
      )}
    </div>
  )
}

function truncateLongAddress(address, keep = 8) {
  let first = address.substring(0, keep)
  let last = address.substring(address.length - keep, address.length)

  return `${first}...${last}`
}

const DepositList = ({ assetDetail, currentPage, pageSize }) => {
  const { filterType, filter } = useSnapshot(assetStore)
  const config = {
    page: currentPage,
    limit: pageSize,
    project: assetDetail.project,
    assetUid: assetDetail.assetUid
  }
  if (filter != null) {
    config[filterType] = filter
    config.page = 1
  }

  const { data: response, mutate, error } = useSWR(serialize(SubWalletService.getDeposit, config))
  if (error) {
    return <Typography.Text>{error.response}</Typography.Text>
  }

  if (!response) {
    return <Spin />
  }

  const { transactions, meta } = response.messageBody

  if (meta.transactionCount === 0) {
    return <Typography.Title level={4}>No items</Typography.Title>
  }

  const items = transactions.map((transaction) => {
    const date = new Date(transaction.createdAt * 1000)
    const formattedTime = new Intl.DateTimeFormat('en-GB', {
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric'
    })
      .format(date)
      .toString()

    const formattedDate = new Intl.DateTimeFormat('en-GB', {
      month: 'numeric',
      day: 'numeric',
      year: 'numeric'
    })
      .format(date)
      .toString()

    return { ...transaction, formattedTime, formattedDate }
  })

  const CopyableLink = ({ value }) => {
    const [duration, setDuration] = useState(null)
    const [showSuccess, toggleSuccess] = useToggle(false)

    useInterval(() => {
      if (duration != null) {
        toggleSuccess()
      }

      setDuration(null)
    }, [duration])

    return (
      <Tooltip title={showSuccess ? 'Copied' : 'Copy'}>
        <a
          onClick={() =>
            navigator.clipboard.writeText(value).then(
              function () {
                toggleSuccess()
                setDuration(2000)
              },
              function (err) {
                console.error('Async: Could not copy text: ', err)
              }
            )
          }
        >
          {showSuccess ? <CheckOutlined /> : <CopyOutlined />}
        </a>
      </Tooltip>
    )
  }

  return (
    <>
      <List
        size="small"
        itemLayout="horizontal"
        dataSource={items}
        renderItem={(item) => {
          return (
            <List.Item style={{ padding: "8px 0px" }}>
              <List.Item.Meta
                description={
                  <Row gutter={(24, 12)} align="middle" style={{ rowGap: 5 }}>
                    <Col lg={{ span: 2, order: 1 }} xs={{ span: 24, order: 1 }} align="center">
                      {isMobile ? (
                        <><Typography.Text style={{ display: 'block' }}>
                          {`${item.formattedTime} (${item.formattedDate})`}
                        </Typography.Text></>
                      ) : (
                        <><Typography.Text style={{ display: 'block' }}>
                          {item.formattedTime}
                        </Typography.Text><Typography.Text>{item.formattedDate}</Typography.Text></>
                      )}
                    </Col>

                    <Col lg={{ span: 1, order: 2 }} xs={{ span: 24, order: 2 }} align="center">
                    <Blockies seed={item.toAddress} className={styles.identicons}/>
                    </Col>

                    <Col lg={{ span: 5, order: 2 }} xs={{ span: 24, order: 3 }}>
                      <Typography.Text type="secondary" style={{ display: 'block' }} align="center">
                      <WalletOutlined />{" "}Subwallet Address
                      </Typography.Text>
                      <div className={styles.codeFlex}>
                        <Typography.Text code>
                          <Tooltip title={item.toAddress}>
                            {truncateLongAddress(item.toAddress, 12)}
                          </Tooltip>
                          <CopyableLink value={item.toAddress} />
                        </Typography.Text>
                      </div>
                    </Col>

                    <Col lg={{ span: 4, order: 4 }} xs={{ span: 24, order: 4 }}>
                      <Typography.Text type="secondary" style={{ display: 'block' }} align="center">
                        From
                      </Typography.Text>
                      <div className={styles.codeFlex}>
                        <Typography.Text code>
                          <Tooltip title={item.fromAddress}>
                            {truncateLongAddress(item.fromAddress, 8)}
                          </Tooltip>
                          <CopyableLink value={item.fromAddress} />
                        </Typography.Text>
                      </div>
                    </Col>

                    <Col lg={{ span: 4, order: 5 }} xs={{ span: 24, order: 5 }}>
                      <Typography.Text type="secondary" style={{ display: 'block' }} align="center">
                        Txid
                      </Typography.Text>
                      <div className={styles.codeFlex}>
                        <Typography.Text code>
                          <Tooltip title={item.hash}>
                            {truncateLongAddress(item.hash, 8)}
                          </Tooltip>
                          <CopyableLink value={item.hash} />
                        </Typography.Text>
                      </div>
                    </Col>

                    <Col lg={{ span: 4, order: 6 }} xs={{ span: 12, order: 6 }} align="center">
                      <Tooltip title="Deposits received in subwallet">
                        <Typography.Text type="secondary" className={styles.subwalletAlign}>
                          Amount
                        </Typography.Text>
                        <Typography.Text type="success" className={styles.subwalletAlign}>
                          {item.value.toLocaleString()} {` ${assetDetail.symbol}`}
                        </Typography.Text>
                      </Tooltip>
                    </Col>

                    <Col lg={{ span: 4, order: 7 }} xs={{ span: 12, order: 7 }} align="center">
                      <Tooltip title="Sum of total deposit transaction and platform fee">
                        <Typography.Text type="secondary" className={styles.subwalletAlign}>
                          Fee Incurred
                        </Typography.Text>
                        <Typography.Text type="danger" className={styles.subwalletAlign}>
                          -{item.platformFee.toLocaleString()} {` ${assetDetail.symbol}`}
                        </Typography.Text>
                      </Tooltip>
                    </Col>
                  </Row>
                }
              />
              <div>
                <SendButton value={item.hash} status={item.status} isNotified={item.isNotified} mutate={mutate} />
              </div>
            </List.Item>
          )
        }}
      />
    </>
  )
}

export default DepositList
