import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { UserOutlined } from '@ant-design/icons'
import { Typography, Card, Tabs, Button, Form, Input, message } from 'antd'

import styles from '../index.less'
import AuthService from '../../../network/auth'

const ForgotPasswordForm = () => {
  const { t } = useTranslation(['login', 'common'])

  const [submitting, setSubmitting] = useState(false)
  const [type, setType] = useState('account')
  const [form] = Form.useForm()

  const handleRequestVerification = async (values) => {
    setSubmitting(true)

    try {
      const response = await AuthService.resetPassword(values)
      console.log(response)

      form.resetFields()
      setSubmitting(false)
      message.success('Please check your email for further instructions', 10)
    } catch (error) {
      console.log(error)
      setSubmitting(false)

      message.error(error.message === 'USER_NOT_FOUND' ? 'User not found' : error.message)
    }
  }

  return (
    <>
      <Card className={styles.authCard}>
        <div style={{ marginBottom: 20 }}>
          <Typography.Text type="secondary">Don't worry, we'll get you back in no time! Make sure to check your email inbox for the reset link.</Typography.Text>
        </div>
        <Form form={form} layout="vertical" onFinish={handleRequestVerification}>
          <Form.Item className={styles.authFormItem} name="email" rules={[{ required: true, message: 'Please enter your email!' }]}>
            <Input
              className={styles.authInputForm}
              size="large"
              prefix={<UserOutlined className={styles.prefixIcon} />}
              placeholder="Email"
            />
          </Form.Item>

          <div
            style={{
              marginBottom: 24
            }}
          >
            <Link
              to="/"
              style={{
                float: 'right'
              }}
            >
              <p><u>Back to Login</u></p>
            </Link>
          </div>

          <Button
            loading={submitting}
            size="large"
            key="done"
            type="primary"
            htmlType="submit"
            style={{ width: '100%', borderRadius: 20 }}
          >
            Send Reset Link
          </Button>
        </Form>
      </Card>
      {/* <ModalForm
        formTitle="Reset Password"
        inputs={[
          {
            inputName: 'token',
            inputLabel: 'Verification Token',
            rules: [
              {
                required: true,
                message: 'Please enter the verification token!'
              }
            ]
          },
          {
            inputName: 'password',
            inputLabel: 'Password',
            type: 'password',
            rules: [
              {
                required: true,
                message: 'Please enter your new password!'
              }
            ]
          },
          {
            inputName: 'confirm_password',
            inputLabel: 'Confirm Password',
            type: 'password',
            rules: [
              {
                required: true,
                message: 'Please confirm your password!'
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve()
                  }
                  return Promise.reject(
                    new Error('The two passwords that you entered do not match!')
                  )
                }
              })
            ],
            hasFeedback: true,
            dependencies: ['password']
          }
        ]}
        handleCancel={() => {
          setShowModal(false)
        }}
        handleSubmit={handleResetPassword}
        visible={showModal}
        buttonLabel="Confirm"
        loading={submitting}
      /> */}
    </>
  )
}

export default ForgotPasswordForm
