import React from 'react'

import styles from './index.less'
import ChangePasswordForm from './components/ChangePasswordForm'

const ChangePasswordPage = () => {
  return (
    <div className={styles.main}>
      <ChangePasswordForm />
    </div>
  )
}

export default ChangePasswordPage
