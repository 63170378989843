import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Card, Tabs, Button, Form, Input, message, Typography } from 'antd'
import { useHistory } from 'react-router'

import AuthService from '../../../network/auth'

const ChangePasswordForm = () => {
  let history = useHistory()
  const { t } = useTranslation(['login', 'common'])

  const [submitting, setSubmitting] = useState(false)
  const [form] = Form.useForm()

  const handleResetPassword = async (values) => {
    setSubmitting(true)

    try {
      const response = await AuthService.changePasswordFromUser({
        old: values.old_password,
        new: values.new_password
      })
      console.log(response)

      message.success('Password changed successfully!').then(() => history.push('/'))
      setSubmitting(false)
      form.resetFields()
    } catch (error) {
      console.log(error)
      let messageString = error.messages

      if (error.message === 'WRONG_PASSWORD') {
        messageString = 'Invalid old password'
      }

      message.error(messageString)
      setSubmitting(false)
    }
  }

  return (
    <Card style={{borderRadius:15, boxShadow:"5px 8px 24px 5px rgba(208, 216, 243, 0.6)"}}>
      <Form form={form} layout="vertical" onFinish={handleResetPassword}>
        <Typography.Title level={4} align="center" style={{paddingBottom:20}}>Change Password</Typography.Title>

        <Form.Item
          name="old_password"
          label="Old Password"
          rules={[
            {
              required: true,
              message: 'Please enter your old password!'
            }
          ]}
        >
          <Input.Password size="large" style={{borderRadius:20}}/>
        </Form.Item>

        <Form.Item
          name="new_password"
          label="New Password"
          rules={[
            {
              required: true,
              message: 'Please enter your new password!'
            }
          ]}
        >
          <Input.Password size="large" style={{borderRadius:20}}/>
        </Form.Item>

        <Form.Item
          name="confirm_password"
          label="Confirm Password"
          dependencies={['password']}
          rules={[
            {
              required: true,
              message: 'Please confirm your password!'
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('new_password') === value) {
                  return Promise.resolve()
                }
                return Promise.reject(new Error('The two passwords that you entered do not match!'))
              }
            })
          ]}
        >
          <Input.Password size="large" style={{borderRadius:20}}/>
        </Form.Item>

        <Button
          loading={submitting}
          size="large"
          key="done"
          type="primary"
          htmlType="submit"
          style={{ width: '100%', borderRadius:20 }}
        >
          Submit
        </Button>
      </Form>
      </Card>
  )
}

export default ChangePasswordForm
