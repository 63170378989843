import React, { useState, useEffect } from 'react'
import { Button, Modal, Form, Input } from 'antd'

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required: '${label} is required!'
}
/* eslint-enable no-template-curly-in-string */

const ModalForm = (props) => {
  const { formTitle, handleCancel, visible, buttonLabel, loading } = props
  const [submit, setSubmit] = useState(0)

  return (
    <Modal
      destroyOnClose={true}
      visible={visible}
      maskClosable={false}
      title={formTitle}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" loading={loading} onClick={() => setSubmit(submit + 1)}>
          {buttonLabel}
        </Button>
      ]}
    >
      <FormWrapper {...props} submit={submit} setSubmit={setSubmit} />
    </Modal>
  )
}

const FormWrapper = (props) => {
  const { inputs, handleSubmit, initialValues, submit, setSubmit } = props
  const [form] = Form.useForm()

  useEffect(() => {
    if (submit > 0) {
      form.submit()
      setSubmit(0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submit])

  return (
    <Form
      form={form}
      preserve={false}
      onFinish={handleSubmit}
      layout="vertical"
      validateMessages={validateMessages}
      initialValues={initialValues}
    >
      {inputs.map(
        (
          {
            hidden = false,
            inputName,
            inputLabel,
            inputPlaceholder,
            rules = [{ required: false }],
            type,
            ...rest
          },
          index
        ) => {
          return (
            <Form.Item
              key={index}
              hidden={hidden}
              name={inputName}
              label={inputLabel}
              rules={rules}
              {...rest}
            >
              {type === 'password' ? (
                <Input.Password placeholder={inputPlaceholder} />
              ) : (
                <Input placeholder={inputPlaceholder} />
              )}
            </Form.Item>
          )
        }
      )}
    </Form>
  )
}

export default ModalForm
