import client from './request'

const get = `/user/masterwallet`

const getDeposits = `/user/masterwallet/deposits`

const getWithdrawals = `/user/masterwallet/withdrawals`

const MasterWalletService = {
  get,
  getDeposits,
  getWithdrawals
}

export default MasterWalletService
