import React, { useState } from 'react'
import { Tabs } from 'antd'
import { useTranslation } from 'react-i18next'

import styles from './index.less'
import SignInForm from './components/SignInForm'
import SignUpForm from './components/SignUpForm'

const AuthPage = () => {
  const { t } = useTranslation(['login', 'common'])
  const [type, setType] = useState('sign_in')

  return (
    <div className={styles.main}>
      <Tabs style={{border: 0}} activeKey={type} onChange={setType}>
        <Tabs.TabPane key="sign_in" tab={t('login:accountLogin.tab')}>
          <SignInForm />
        </Tabs.TabPane>
        <Tabs.TabPane key="sign_up" tab={t('login:accountSignup.tab')}>
          <SignUpForm />
        </Tabs.TabPane>
      </Tabs>
    </div>
  )
}

export default AuthPage
