import React from 'react'
import { PageContainer } from '@ant-design/pro-layout'
import { Spin, Space, Card, Table, Button, Typography, Tag, Popover, Tooltip } from 'antd'
import useSWR from 'swr'
import { Link } from 'react-router-dom'
import moment from 'moment'

import ErrorPage from '../../error'
import AssetService from '../../../network/asset'
import styles from '../index.less'

import { FileDoneOutlined, RightOutlined, RocketTwoTone } from '@ant-design/icons'
import Icon from '@ant-design/icons';
import { ReactComponent as assetsIcon } from "../../../diamond.svg";
const AssetIcon = () => <Icon component={assetsIcon} />

var countDecimals = function (value) {
  if ((value % 1) != 0)
    return value.toString().split(".")[1].length;
  return 0;
};

const CoinItem = ({ row, assets }) => {
  const selectedAsset = React.useMemo(() => {
    return assets.find((asset) => asset.uid === row.uid)
  }, [assets, row.uid])

  switch (row.network) {
    case 'ETHEREUM':
      row.color = 'purple'
      break;
    case 'TRON':
      row.color = 'red'
      break;
    default:
      row.color = 'cyan'
  }

  return (
    <Tooltip title={`Network: ${row.network}`}>
      <Space>
        <img src={selectedAsset.logoUrl} alt={selectedAsset.symbol} width="20" height="20" />
        <Space direction="vertical" size="0">
          <Typography.Text style={{ display: 'block' }}>{`${row.symbol}`} <Tag style={{ borderRadius: 20, fontSize: 10 }} color={row.color}>{row.type}</Tag></Typography.Text>
          <Typography.Text type="secondary">{row.name}</Typography.Text>
        </Space>
      </Space>
    </Tooltip>
  )
}

const AssetList = ({ assets }) => {
  const { data: response, error } = useSWR(AssetService.summary)
  if (error) {
    // assume is empty projects
    if (error.message === 'INVALID_PROJECT') {
      return null
    }

    return (
      <PageContainer>
        <ErrorPage error={error} />
      </PageContainer>
    )
  }
  if (!response) {
    return (
      <PageContainer>
        <Spin />
      </PageContainer>
    )
  }

  const { data } = response.messageBody

  const ContractModel = ({ row }) => {
    return (
      <Card style={{ width: 400 }}>
        <p><Typography.Text type="secondary" style={{ fontSize: '0.9em' }}>
          {`This is a smart contract on the ${row.network} blockchain. The Multichain team has a strict policy on listing only verified token contracts.`}
        </Typography.Text>
        </p>
        <Typography.Text type="secondary" style={{ fontSize: '0.8em', display: 'inline-block' }}>
          Contract Address
        </Typography.Text>
        <p>
          <Typography.Text code copyable>
            {row.contract}
          </Typography.Text>
        </p>
      </Card>
    )
  }

  return (
    <Card
      title={
        <><Typography.Title level={4} style={{ margin: 0 }} align="center">
          <AssetIcon />{" "}
          Crypto Assets
        </Typography.Title>
          <Typography.Text type="secondary" style={{ display: 'block', margin: 0 }} align="center">
            Sum of all your project Masterwallet balance
          </Typography.Text>
          <Typography.Text type="secondary" style={{ fontSize: 12, display: 'block', margin: 0 }} align="center">
            {`Last updated at: ${moment().format("HH:mm:ss a").toLocaleString()}`}
          </Typography.Text>
        </>
      }
      bordered={false}
      className={styles.fillRow}
      style={{ boxShadow: "5px 8px 24px 5px rgba(208, 216, 243, 0.6)", borderRadius: 10 }}
    >
      <Table
        size="small"
        style={{ margin: 0, padding: 10 }}
        scroll={{ x: 600 }}
        columns={[
          // {
          //   title: 'Supported Assets',
          //   dataIndex: 'symbol',
          //   key: 'symbol',
          //   render: (value, row) => {
          //     return (
          //       <Space direction="vertical">
          //         <Space>
          //           <Typography.Text
          //             type="secondary"
          //             style={{ width: 70, display: 'inline-block' }}
          //           >
          //             Assets
          //           </Typography.Text>
          //           <Typography.Text>{value}</Typography.Text>
          //           <Typography.Text type="secondary">{row.name}</Typography.Text>
          //         </Space>
          //         <Space>
          //           <Typography.Text
          //             type="secondary"
          //             style={{ width: 70, display: 'inline-block' }}
          //           >
          //             Network
          //           </Typography.Text>
          //           <Typography.Text>{row.network}</Typography.Text>
          //         </Space>
          //         <Space>
          //           <Typography.Text
          //             type="secondary"
          //             style={{ width: 70, display: 'inline-block' }}
          //           >
          //             Total
          //           </Typography.Text>
          //           <Space direction="vertical" style={{ gap: 0 }}>
          //             <Typography.Text>{row.balance}</Typography.Text>
          //             <Typography.Text type="secondary">
          //               ~ $
          //               {Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(
          //                 row.balance
          //               )}{' '}
          //               * usd value
          //             </Typography.Text>
          //           </Space>
          //         </Space>
          //         <Space>
          //           <Typography.Text
          //             type="secondary"
          //             style={{ width: 70, display: 'inline-block' }}
          //           >
          //             Deposit Count
          //           </Typography.Text>
          //           <Typography.Text>{row.depositTxCount}</Typography.Text>
          //         </Space>
          //         <Space>
          //           <Typography.Text
          //             type="secondary"
          //             style={{ width: 70, display: 'inline-block' }}
          //           >
          //             Action
          //           </Typography.Text>
          //           <Link to={`assets?uid=${row.uid}`}>
          //             <Button>Go to dashboard</Button>
          //           </Link>
          //         </Space>
          //       </Space>
          //     )
          //   },
          //   responsive: ['xs']
          // },
          {
            title: 'Assets',
            dataIndex: 'symbol',
            key: 'symbol',
            render: (value, row) => {
              return <CoinItem row={row} assets={assets} />
            }
            // responsive: ['md']
          },
          {
            title: 'Price(USD)',
            dataIndex: 'priceUsd',
            key: 'priceUsd',
            align: 'center',
            render: (value, row) => {
              if (value) {
                return (<><Tooltip title={"Queried live from Coingecko"}>
                  ~${Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(value)}
                </Tooltip>
                </>)
              } else {
                return (<>{`-`}</>)
              }

            }
            // responsive: ['md']
          },
          {
            title: 'Total Project Balance',
            dataIndex: 'balance',
            key: 'balance',
            align: 'right',
            render: (value, row) => {
              if (!row.balanceUsd) { return <Typography.Text style={{ display: 'block', color: "black" }}><strong>{value}</strong></Typography.Text> }
              return (
                <>
                  <Tooltip title="Sum of balances across all projects">
                    <Typography.Text style={{ display: 'block', color: "black" }}><strong>{value}</strong></Typography.Text>
                    <Typography.Text type="secondary" style={{ fontSize: 13 }}>
                      ~ ${Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(row.balanceUsd)}
                    </Typography.Text>
                  </Tooltip>
                </>
              )
            }
            // responsive: ['md']
          },
          {
            title: 'Subwallet Deposit',
            dataIndex: 'depositTxCount',
            key: 'depositTxCount',
            align: 'center',
            render: (value, row) => {
              return (<><Tooltip title="Total number of deposits across all projects"><strong>{Intl.NumberFormat('en-US').format(value)}</strong>{' '}tx(s)</Tooltip></>)
            }
            // responsive: ['md']
          },
          {
            title: 'Contract',
            dataIndex: 'contract',
            key: 'contract',
            align: 'center',
            render: (value, row) => {
              return (
                value ? (
                  <Popover content={<ContractModel row={row} />} trigger="click">
                    <Tag style={{ borderRadius: 15, padding: "0px 10px" }}><FileDoneOutlined style={{ color: "#52c41a" }} />{" "}View</Tag>
                  </Popover>
                ) : (<><Tooltip title={`This is the native coin (not token) on the ${row.network} blockchain`}>                <Typography.Text type="secondary" style={{ fontSize: 13 }}>
                {`Native Coin`}
              </Typography.Text></Tooltip></>)
              )
            }
            // responsive: ['md']
          },
          {
            title: 'Minimum Deposit',
            dataIndex: 'minimumValue',
            key: 'minimumValue',
            align: 'right',
            render: (value, row) => {
              let valueDecimals = countDecimals(value)
              let decimalsToFix = valueDecimals
              if (valueDecimals < 6) {
                decimalsToFix = countDecimals(value)
              } else {
                if (row.decimal > 6) {
                  decimalsToFix = 6
                } else {
                  decimalsToFix = row.decimal
                }
              }

              return (<><Tooltip title={"Value may change because network fees are dynamic"}>
                <Typography.Text style={{ display: 'block', color: "black" }}>{`${Intl.NumberFormat('en-US', { maximumFractionDigits: decimalsToFix }).format(value)}`}</Typography.Text>
                <Typography.Text type="secondary" style={{ fontSize: 13 }}>
                  {`${row.symbol}`}
                </Typography.Text>
              </Tooltip></>)
            }
            // responsive: ['md']
          },
          {
            title: 'Network Fee',
            dataIndex: 'networkFee',
            key: 'networkFee',
            align: 'right',
            render: (value, row) => {
              let valueDecimals = countDecimals(value)
              let decimalsToFix = valueDecimals
              if (valueDecimals < 6) {
                decimalsToFix = countDecimals(value)
              } else {
                if (row.decimal > 6) {
                  decimalsToFix = 6
                } else {
                  decimalsToFix = row.decimal
                }
              }


              return (<><Tooltip title={"Network fees are dynamic and deducted from deposit value before crediting Masterwallet"}>
                <Typography.Text style={{ display: 'block', color: "black" }}>{`${Intl.NumberFormat('en-US', { maximumFractionDigits: decimalsToFix }).format(value)}`}</Typography.Text>
                <Typography.Text type="secondary" style={{ fontSize: 13 }}>
                  {`${row.symbol}`}
                </Typography.Text>
              </Tooltip></>)
            }
            // responsive: ['md']
          },
          {
            title: 'Platform Fee',
            dataIndex: 'platformFeePercent',
            key: 'platformFeePercent',
            align: 'center',
            render: (value, row) => {
              return (<><Tooltip title={"Deducted based on deposit value before crediting Masterwallet"}>{Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(value) + '%'}</Tooltip></>)
            }
            // responsive: ['md']
          },
          {
            title: 'Navigate',
            dataIndex: 'action',
            key: 'action',
            align: 'center',
            render: (_, row) => {
              return (
                <Tooltip title="Go to Masterwallet asset page to view balance and wallet activity.">
                  <Link to={`assets?uid=${row.uid}`}>
                    <Button style={{ borderRadius: 20 }}>Masterwallet <RightOutlined /></Button>
                  </Link>
                </Tooltip>
              )
            }
            // responsive: ['md']
          }
        ]}
        dataSource={data}
        pagination={false}
      />
    </Card>
  )
}

export default AssetList
