import client from './request'

const get = '/user/asset'

const summary = '/user/assets/summary'

const getUpload = '/user/withdraw/mass/upload'

const uploadCsv = (data) => {
  const formData = new FormData()
  formData.append('file', data)
  return client.post('/user/withdraw/mass/upload', formData)
}

const sendCode = () => {
  return client.post('/user/email/verification')
}

const singleWithdraw = (data) => {
  return client.post('/user/withdraw/single', data)
}

const massWithdraw = (data) => {
  return client.post('/user/withdraw/mass', data)
}

const AssetService = {
  get,
  summary,
  getUpload,
  uploadCsv,
  sendCode,
  singleWithdraw,
  massWithdraw
}

export default AssetService
