import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet, HelmetProvider } from 'react-helmet-async'

import styles from './UserLayout.less'

const UserLayout = (props) => {
  const { children } = props
  // const {
  //   route = {
  //     routes: []
  //   }
  // } = props
  // const { routes = [] } = route
  // const {
  //   children,
  //   location = {
  //     pathname: ''
  //   }
  // } = props
  // const { formatMessage } = useIntl()
  // const { breadcrumb } = getMenuData(routes)
  // const title = getPageTitle({
  //   pathname: location.pathname,
  //   formatMessage,
  //   breadcrumb,
  //   ...props
  // })

  console.log(styles)

  return (
    <HelmetProvider>
      {/* <Helmet>
        <title>{title}</title>
        <meta name="description" content={title} />
      </Helmet> */}

      <div className={styles.container}>
        <div className={styles.lang}>{/* <SelectLang /> */}</div>
        <div className={styles.content}>
          <div className={styles.top}>
            <div className={styles.header}>
              <Link to="/">
                <img alt="logo" className={styles.logo} src="/logo.png" />
              </Link>
            </div>
            <div className={styles.desc}>
              {/* <FormattedMessage
                id="pages.layouts.userLayout.title"
                defaultMessage="
                Multi Chain"
              /> */}
            </div>
          </div>
          {children}
        </div>
      </div>
    </HelmetProvider>
  )
}

export default UserLayout
// export default connect(({ settings }) => ({ ...settings }))(UserLayout)
