import React, { useState } from 'react'
import { isMobile } from 'react-device-detect';
import { Typography, Tooltip, Select, Divider, Space, Row, Col, Card } from 'antd'
import { useLocation } from 'react-use'

import styles from '../index.less'
import AssetTabs from './AssetTabs'

const { Option } = Select

const AssetWrapper = ({ projects, assets }) => {
  const { search } = useLocation()
  const initialAsset = new URLSearchParams(search).get('uid')

  const { details } = projects

  const [selectedProject, setSelectedProject] = useState(details[0].apiKey)
  const [selectedAsset, setSelectedAsset] = useState(initialAsset ?? assets[0].uid)

  const handleSelect = async (type, value) => {
    if (type === 'project') {
      setSelectedProject(value)
    } else {
      setSelectedAsset(value)
    }
  }

  return (
    <Card style={{width:"100%", boxShadow: "5px 8px 24px 5px rgba(208, 216, 243, 0.6)", borderRadius: 20}}>
    <div>
        <Tooltip title="Select a project and asset to view wallet data">
          <Row gutter={(24, 12)} align="middle">
            <Col lg={{ span: 12, order: 1 }} md={{ span: 12, order: 1 }} sm={{ span: 12, order: 1 }} xs={{ span: 24, order: 1 }} align={isMobile ? "center":"right"}>
              <Select
                onChange={(value) => {
                  handleSelect('project', value)
                }}
                className={styles.dropdownList}
                size="large"
                value={selectedProject}
              >
                {details.map((detail) => (
                  <Option key={detail.apiKey} value={detail.apiKey}>
                    {<Typography.Title level={5} style={{ color: "#1890ff", marginBottom: "0", paddingTop: "6px" }} align="center">{detail.label}</Typography.Title>}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col lg={{ span: 12, order: 2 }} md={{ span: 12, order: 2 }} sm={{ span: 12, order: 2 }} xs={{ span: 24, order: 2 }} align={isMobile ? "center":"left"}>
              <Select
                onChange={(value) => {
                  handleSelect('asset', value)
                }}
                className={styles.dropdownList}
                size="large"
                value={selectedAsset}
              >
                {assets.map((asset) => (
                  <Option key={asset.uid} value={asset.uid}>
                    <div align="center">
                    <Space direction="horizontal" className={styles.centerFlex}>
                      <img
                        src={asset.logoUrl}
                        alt={asset.symbol}
                        width="20"
                        height="20"
                      />
                      {`${asset.symbol} (${asset.type})`}
                    </Space>
                    </div>
                  </Option>
                ))}
              </Select>
            </Col>
          </Row>
        </Tooltip>
      <Divider style={{border:0}}/>

      <AssetTabs project={selectedProject} asset={selectedAsset} />
    </div>
    </Card>
  )
}

export default AssetWrapper
