import axios from 'axios'
import qs from 'qs'
import auth, { logout } from '../store/auth'

const isHandlerEnabled = (config = { handlerEnabled: true }) => {
  return config.hasOwnProperty('handlerEnabled') && !config.handlerEnabled ? false : true
}

const requestHandler = (request) => {
  if (isHandlerEnabled(request)) {
    const token = localStorage.getItem('token') || undefined

    if (token != null && typeof token !== 'undefined') {
      request.headers.Authorization = `Bearer ${token}`
    }
  }

  return request
}

const errorHandler = (error) => {
  //console.log(error?.response)

  // if (error?.response?.status === 401) {
  //   // unauthorized
  //   localStorage.clear()
  //   window.location.reload()
  // }

  if (error?.response?.status === 422) {
    // unprocessable entity
    return Promise.reject({
      message:
        error?.response?.data?.errors?.map((e) => `${e.field} - ${e.message}`).join('\n') ??
        'Please contact customer support'
    })
  }

  if (isHandlerEnabled(error.config)) {
    // Handle errors
    if ((error.response?.data?.message == 'jwt expired') && (localStorage.getItem("token") !== null)) {
      logout(true)
    }
    
    return Promise.reject({
      message:
        error.response?.data?.errorCode ??
        error.response?.data?.message ??
        error.response?.data?.error ??
        error.response?.data?.code ??
        'Please try again later',
      body: error.response?.data?.errorBody
    })
  }
  return Promise.reject({ ...error })
}

const successHandler = (response) => {
  if (isHandlerEnabled(response.config)) {
    // Handle responses
  }

  return response
}

const client = axios.create({
  baseURL: process.env.REACT_APP_PUBLIC_URL
})
client.interceptors.request.use((request) => requestHandler(request))
client.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error)
)

export const fetcher = async (...args) => {
  const url = args[0]
  return client.get(url).then((res) => res.data)

  // const params = args[1]
  // let urlWithParam = url

  // if (params != null) {
  //   let filterString = filterQuery(params)
  //   urlWithParam += filterString
  // }

  // return client.get(urlWithParam).then((res) => res.data)
}

export const serialize = (url, params) => {
  if (typeof params === 'object' || Array.isArray(params)) {
    const matches = url.match(/^(.+?)(\?(.*))?$/)
    const urlWithoutQueryString = (matches && matches[1]) || url
    const queryStringDataFromUrl = matches && matches[3] ? qs.parse(matches[3]) : {}
    const queryString = qs.stringify(
      { ...queryStringDataFromUrl, ...params },
      { arrayFormat: 'indices' }
    )
    if (queryString) {
      return `${urlWithoutQueryString}?${queryString}`
    }
    return url
  }

  return url
}

export default client
