import React, { useState } from 'react'
import { isMobile } from 'react-device-detect';
import { useMemo } from 'react'
import { Empty, Card, Select, Tooltip, Typography, Tag, Row, Col, Input, Pagination } from 'antd'
import { useSnapshot } from 'valtio'
import useSWR from 'swr'

import styles from '../index.less'
import assetStore from '../../../store/asset'
import DepositList from './Lists/DepositList'
import SubWalletService from '../../../network/subwallet'
import { serialize } from '../../../network/request'

const DepositHistories = ({ assetDetail }) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize] = useState(10)
  const { filterType, filter, list } = useSnapshot(assetStore)

  const selectedAsset = useMemo(() => {
    return list.find((element) => {
      return element.uid === assetDetail.assetUid
    })
  }, [assetDetail.assetUid, list])

  const config = {
    page: 1,
    limit: pageSize,
    project: assetDetail.project,
    assetUid: assetDetail.assetUid
  }
  if (filter != null) {
    config[filterType] = filter
  }

  const { data: response } = useSWR(serialize(SubWalletService.getDeposit, config))
  const { transactions, meta } = response?.messageBody ?? {}

  const onPageChange = (page) => {
    setCurrentPage(page)
  }

  return (
    <Card style={{ borderRadius: 20 }} className={styles.assetCard}>
      <Row gutter={[24, 12]}>
        { meta? (<>
        <Col xl={8} xs={24}>
          <Row gutter={[24, 8]}>
            <Col span={24}>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: 12 }}>
                {selectedAsset != null && (
                  <img
                    src={selectedAsset.logoUrl}
                    alt={assetDetail.symbol}
                    width="30"
                    height="30"
                  />
                )}
                <Typography.Title level={4} style={{ margin: '0 5px' }}>
                  {selectedAsset.symbol} ({selectedAsset.type}) Deposits
                </Typography.Title>
                {assetDetail.isWithdrawable ? <Tooltip title="Asset is active"><Tag style={{ fontSize: 15, borderRadius: 15, padding: "3px 15px 3px 15px" }} color="green">Online</Tag></Tooltip>
                  :
                  <Tooltip title="Withdrawal is temporarily disabled"><Tag style={{ fontSize: 15, borderRadius: 15, padding: "3px 15px 3px 15px" }} color="red">Disabled</Tag></Tooltip>}
              </div>
            </Col>
          </Row>
        </Col>
        <Col xl={16} xs={24}>
          <Row gutter={[24, 8]}>
            <Col lg={{ span: 4, order: 1 }} xs={{ span: 24, order: 1 }} align={isMobile ? "center" : "right"}>
              <Select
                onChange={(value) => {
                  assetStore.filterType = value
                }}
                className={styles.dropdownListSearch}
                value={filterType}
              >
                <Select.Option value={'hash'}>Txid</Select.Option>
                <Select.Option value={'address'}>Address</Select.Option>
              </Select>
            </Col>
            <Col lg={{ span: 20, order: 2 }} xs={{ span: 24, order: 2 }} align={isMobile ? "center" : "right"} className={styles.search}>
              <Input.Search
                placeholder={`Search by ${filterType}`}
                allowClear
                onSearch={(value) => {
                  assetStore.filter = value
                }}
                onChange={(e) => {
                  assetStore.filter = e.target.value
                }}
                value={filter}
              />
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <>
            <DepositList assetDetail={assetDetail} currentPage={currentPage} pageSize={pageSize} />
            <div style={{ display: 'none' }}>
              {currentPage <= meta?.transactionCount / pageSize && (
                <DepositList
                  assetDetail={assetDetail}
                  currentPage={currentPage + 1}
                  pageSize={pageSize}
                />
              )}
            </div>

            {transactions?.length > 0 && (
              <div align="center">
                <Pagination
                  onChange={onPageChange}
                  defaultCurrent={1}
                  total={meta?.transactionCount}
                  pageSize={pageSize}
                />
              </div>
            )}
          </>
        </Col>
        </>) : (
          <><Col span={20} xs={{ span: 24 }} align="center"> <div style={{ padding: 50 }}><Empty
            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
            imageStyle={{
              height: 100,
            }}
            description={
              <span>
                Start generating subwallets <a href="https://docs.multichain.info/start/api/private-api" target="_blank">(Create Subwallets API)</a> to begin receiving crypto deposits or to host blockchain wallets.
              </span>
            }
            style={{ maxWidth: 500 }}
          >
          </Empty>
          </div>
          </Col></>
        )}
      </Row>
    </Card>
  )
}

export default DepositHistories
