import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Divider, Tag, Typography, Card, Tabs, Button, Form, Input, message } from 'antd'
import { LockFilled } from '@ant-design/icons'
import { useHistory } from 'react-router'

import AuthService from '../../../network/auth'
import { useQuery } from '../../../utility'
import styles from '../index.less'

const ResetPasswordForm = () => {
  let history = useHistory()
  let query = useQuery()
  const { t } = useTranslation(['login', 'common'])
  const token = query.get('token')
  const email = query.get('email')

  const [submitting, setSubmitting] = useState(false)
  const [form] = Form.useForm()

  const handleResetPassword = async (values) => {
    setSubmitting(true)

    try {
      const response = await AuthService.resetPassword({
        token: values.token,
        email: values.email,
        password: values.password
      })
      console.log(response)

      message.success('Password changed successfully!').then(() => history.push('/'))
      setSubmitting(false)
      form.resetFields()
    } catch (error) {
      console.log(error)
      setSubmitting(false)
    }
  }

  return (
    <>
      <Card className={styles.authCard}>
        <div style={{ marginBottom: 20 }}>
        <div style={{ textAlign: 'center' }}>
          <Typography.Text type="secondary">URL verification:</Typography.Text>
          <Tag color="green"><LockFilled />{" "}<strong>https://</strong>secure.multichain.info</Tag>
        </div>
        <Divider style={{ margin: "15px 0" }} />
          <Typography.Text type="secondary">You're almost there, enter your new password and you're done!</Typography.Text>
        </div>
        <Form
          form={form}
          layout="vertical"
          onFinish={handleResetPassword}
          initialValues={{ token, email }}
        >

          <Form.Item
            className={styles.authFormItem}
            name="token"
            label="Reset Token"
            rules={[{ required: true, message: 'Something\'s wrong, you should have your reset token by default. Pleasey try again.' }]}
          >
            <Input size="large" disabled />
          </Form.Item>

          <Form.Item
            className={styles.authFormItem}
            name="email"
            label="Your Email"
            rules={[{ required: true, message: 'Something\'s wrong, you should have your email declared by default. Please try again.' }]}
          >
            <Input size="large" disabled />
          </Form.Item>

          <Form.Item
            className={styles.authFormItem}
            name="password"
            label="Password"
            rules={[
              {
                required: true,
                message: 'Please enter your new password!'
              }
            ]}
          >
            <Input.Password size="large" className={styles.authInputForm} />
          </Form.Item>

          <Form.Item
            className={styles.authFormItem}
            name="confirm_password"
            label="Confirm Password"
            dependencies={['password']}
            rules={[
              {
                required: true,
                message: 'Please confirm your password!'
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve()
                  }
                  return Promise.reject(new Error('The two passwords that you entered do not match!'))
                }
              })
            ]}
          >
            <Input.Password size="large" className={styles.authInputForm} />
          </Form.Item>
          <div style={{ marginTop: 20, marginBottom: 5, textAlign: 'center' }}>
          <Typography.Text type="secondary">Make sure you remember your password this time 😉</Typography.Text>
        </div>
          <Button
            loading={submitting}
            size="large"
            key="done"
            type="primary"
            htmlType="submit"
            style={{ width: '100%', borderRadius: 20}}
          >
            Submit
          </Button>
        </Form>
      </Card>
    </>
  )
}

export default ResetPasswordForm
