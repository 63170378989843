import React from 'react'
import { PageContainer } from '@ant-design/pro-layout'
import { Spin, Result } from 'antd'
import useSWR from 'swr'
import { useTranslation } from 'react-i18next'

import AssetWrapper from './components/Wrapper'
import ErrorPage from '../error'
import ProjectService from '../../network/project'
import AssetService from '../../network/asset'

const AssetsPage = () => {
  const { t } = useTranslation(['common'])

  const { data: projectResponse, error: projectError } = useSWR(ProjectService.get)
  const { data: assetResponse, error: assetError } = useSWR(AssetService.get)

  if (projectError) {
    console.log(projectError)
    return (
      <PageContainer>
        <ErrorPage error={projectError} />
      </PageContainer>
    )
  }

  if (assetError) {
    console.log(assetError)
    return (
      <PageContainer>
        <ErrorPage error={assetError} />
      </PageContainer>
    )
  }

  if (!projectResponse || !assetResponse) {
    return (
      <PageContainer>
        <Spin />
      </PageContainer>
    )
  }

  const { projects } = projectResponse.messageBody
  const { assets } = assetResponse.messageBody

  console.log(assets)
  
  // If no projects
  if (projects?.details?.length <= 0) {
    return (
      <PageContainer>
        <Result title={t('assets.error.empty-projects')} />
      </PageContainer>
    )
  }

  if (assets.length === 0) {
    return (
      <PageContainer>
        <Result title={t('assets.error.empty-assets')} />
      </PageContainer>
    )
  }

  return (
    <PageContainer>
      <AssetWrapper projects={projects} assets={assets} />
    </PageContainer>
  )
}

export default AssetsPage
