import React, { useMemo, useState } from 'react'
import { Typography, Tabs, Tag, Row, Col, Space, Button, Card, Tooltip } from 'antd'
import { CheckOutlined, CopyOutlined, InfoCircleOutlined } from '@ant-design/icons'
import Measure from 'react-measure'
import { useInterval, useToggle } from 'react-use'
import { useSnapshot } from 'valtio'

import WithdrawModal from './Withdraw'
import styles from '../index.less'
import MasterWalletTransactions from './Lists/MasterWalletTransactions'
import assetStore from '../../../store/asset'

const QRCode = require('qrcode.react')
const { TabPane } = Tabs

const MasterWallet = ({ assetDetail }) => {
  const [dimension, setDimension] = useState(128)
  const [isModalVisible, toggle] = useToggle(false)
  const [mutate, setMutate] = useState(null)
  const { list } = useSnapshot(assetStore)

  const selectedAsset = useMemo(() => {
    return list.find((element) => {
      return element.uid === assetDetail.assetUid
    })
  }, [assetDetail.assetUid, list])

  const toggleModal = (state) => {
    if (state) {
      toggle(state)
    } else {
      toggle()
    }
  }


  function truncateLongAddress(address, keep = 8) {
    let first = address.substring(0, keep)
    let last = address.substring(address.length - keep, address.length)

    return `${first}...${last}`
  }

  const CopyableLink = ({ value }) => {
    const [duration, setDuration] = useState(null)
    const [showSuccess, toggleSuccess] = useToggle(false)

    useInterval(() => {
      if (duration != null) {
        toggleSuccess()
      }

      setDuration(null)
    }, [duration])

    return (
      <Tooltip title={showSuccess ? 'Copied' : 'Copy'}>
        <a
          onClick={() =>
            navigator.clipboard.writeText(value).then(
              function () {
                toggleSuccess()
                setDuration(2000)
              },
              function (err) {
                console.error('Async: Could not copy text: ', err)
              }
            )
          }
        >
          {showSuccess ? <CheckOutlined /> : <CopyOutlined />}
        </a>
      </Tooltip>
    )
  }

  return (
    <Card style={{ borderRadius: 20 }} className={styles.assetCard}>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: 12 }}>
        {selectedAsset != null && (
          <img src={selectedAsset.logoUrl} alt={assetDetail.symbol} width="30" height="30" />
        )}
        <Tooltip title={
          <div>
            <Typography.Text style={{ color: 'white', display: 'block' }}>
              Network: {selectedAsset.network}
            </Typography.Text>
            <Typography.Text style={{ color: 'white' }}>
              Name: {selectedAsset.name}
            </Typography.Text>
          </div>
        }>
          <Typography.Title level={4} style={{ margin: '0 5px' }}>
            {selectedAsset.symbol}{selectedAsset.type == 'coin' ? '' : `(${selectedAsset.type})`} Masterwallet
          </Typography.Title>
        </Tooltip>
        {assetDetail.isWithdrawable ? <Tooltip title="Asset is active"><Tag style={{ fontSize: 15, borderRadius: 15, padding: "3px 15px 3px 15px" }} color="green">Online</Tag></Tooltip> 
        : 
        <Tooltip title="Withdrawal is temporarily disabled"><Tag style={{ fontSize: 15, borderRadius: 15, padding: "3px 15px 3px 15px" }} color="red">Disabled</Tag></Tooltip>}
      </div>

      <div>
        <Row gutter={[24, 12]}>
          {/* Left Column */}
          <Col lg={6} xs={24}>
            <Row gutter={[24, 8]}>
              <Measure
                bounds
                onResize={(contentRect) => {
                  setDimension(contentRect.bounds)
                }}
              >
                {({ measureRef }) => (
                  <Col
                    xs={24}
                    sm={{ offset: 6, span: 12 }}
                    lg={{ offset: 0, span: 24 }}
                    ref={measureRef}
                  >
                    <QRCode value={assetDetail.masterwalletAddr} size={dimension.width - 24} />
                  </Col>
                )}
              </Measure>
              <Col span={24}>
                <div align="center">
                  <pre className={styles.pre}>
                    <Typography.Text>
                      {assetDetail.masterwalletAddr}
                      <CopyableLink value={assetDetail.masterwalletAddr} />
                    </Typography.Text>

                  </pre>
                </div>
              </Col>
              <Col span={24}>
                <pre className={styles.pre}>
                  <code>
                    <Space>
                      <InfoCircleOutlined style={{ color: "#1890ff", fontSize: 15 }} />
                      {"  "}
                      <p style={{ fontSize: 12, color: "#8c8c8c", marginBottom: 0 }}>
                        {`Deposits will be credited after deducting ${assetDetail.transactionFee} ${assetDetail.symbol} network fee and ${(assetDetail.platformFee * 100).toFixed(2)}% platform fee. Minimum deposit must be ${assetDetail.minimumValue} ${assetDetail.symbol} or more to be reflected in balance.`}
                      </p>
                    </Space>
                  </code>
                </pre>
              </Col>
              <Col span={24}>
                <Button
                  type="primary"
                  className={styles.withdrawButton}
                  onClick={() => toggle(true)}
                  disabled={assetDetail.isWithdrawable? false : true}
                >
                  Withdraw
                </Button>
              </Col>
            </Row>
          </Col>

          {/* Right Column */}
          <Col lg={18} xs={24}>
            <Row gutter={[24, 8]}>
              <Col span={24}>
                <Tabs defaultActiveKey="deposits" className={styles.tabs}>
                  <TabPane tab="Deposits" key="deposits">
                    <MasterWalletTransactions assetDetail={assetDetail} type="deposits" />
                  </TabPane>
                  <TabPane tab="Withdrawals" key="withrawals">
                    <MasterWalletTransactions
                      assetDetail={assetDetail}
                      type="withdrawals"
                      shouldMutate={mutate}
                    />
                  </TabPane>
                </Tabs>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>

      <WithdrawModal
        assetDetail={assetDetail}
        visible={isModalVisible}
        handleCloseModal={() => toggleModal(false)}
        handleMutate={() => setMutate(Math.random())}
      // txFee={this.props.txFee}
      />
    </Card>
  )
}

export default MasterWallet
