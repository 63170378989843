import React, { useState, useEffect } from 'react'
import { Typography, Modal, Space, Steps, Button, message, Form } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'

import WithdrawalMethod from './WithdrawalMethod'
import WithdrawalConfirmation from './WithdrawalConfirmation'
import styles from './index.less'
import AssetService from '../../../../network/asset'

const { Step } = Steps

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 }
}

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required: '${label} is required!'
}
/* eslint-enable no-template-curly-in-string */

const handleError = (e) => {
  if (e.message === 'REQUEST_ERROR') {
    return Modal.error({
      title:
        'An error occurred while submitting the request. Please contact support if this issue continues.'
    })
  }

  if (e.message === 'INSUFFICIENT_BALANCE') {
    return Modal.error({
      title: 'Insufficient Balance',
      content: (
        <Space direction="vertical">
          <Space>
            <Typography.Text type="secondary">Network Fee:</Typography.Text>
            <Typography.Text>
              {e.body?.transactionFee ?? e.body?.totalTransactionFee}
            </Typography.Text>
          </Space>
          <Space>
            <Typography.Text type="secondary">Amount:</Typography.Text>
            <Typography.Text>{e.body?.amount ?? e.body?.totalAmount}</Typography.Text>
          </Space>
          <Space>
            <Typography.Text type="secondary">Balance:</Typography.Text>
            <Typography.Text>{e.body?.balance}</Typography.Text>
          </Space>
        </Space>
      )
    })
  }

  if (e.message === 'PLEASE_REQUEST_2FA') {
    return Modal.error({
      title: 'Please request for a new verification code'
    })
  }

  if (e.message === 'INVALID_CODE') {
    return Modal.error({
      title: 'The verification code submitted is invalid'
    })
  }

  if (e.message === 'CODE_EXPIRED') {
    return Modal.error({
      title: 'The verification code submitted is expired'
    })
  }

  if (e.message === 'INVALID_ADDRESS') {
    return Modal.error({
      title: 'The recipient address submitted is invalid'
    })
  }
}

const WithdrawModal = (props) => {
  const { handleCloseModal, assetDetail, visible, handleMutate } = props
  const [currentStep, setCurrentStep] = useState(0)
  const [withdrawMethod, setWithdrawMethod] = useState(null)
  const [stepForm] = Form.useForm()

  const steps = [
    {
      step: 1,
      title: 'Input Withdrawal Details',
      content: <WithdrawalMethod form={stepForm} assetDetail={assetDetail} />
    },
    {
      step: 2,
      title: 'Confirmation',
      content: (
        <WithdrawalConfirmation
          form={stepForm}
          withdrawMethod={withdrawMethod}
          assetDetail={assetDetail}
        />
      )
    }
  ]

  const next = () => {
    setCurrentStep(currentStep + 1)
  }

  const prev = () => {
    setCurrentStep(currentStep - 1)
  }

  const resetForm = () => {
    setCurrentStep(0)
    setWithdrawMethod(null)
    stepForm.resetFields()
  }

  const handleSubmitMethod = () => {
    try {
      const method = stepForm.getFieldValue('method')
      stepForm.getFieldsValue()
      if (method === 'single') {
        stepForm.validateFields(['recipient', 'asset', 'value']).then((values) => {
          setWithdrawMethod({ ...values, method })
          next()
        })
      }

      if (method === 'mass') {
        stepForm.validateFields(['file']).then((values) => {
          setWithdrawMethod({ ...values, method })
          next()
        })
      }

      return
    } catch (error) {
      message.error('Unable to move to the next step. Please refresh and try again.')
    }
  }

  const handleSubmitWithdrawal = async (values) => {
    if (values.method === 'single') {
      try {
        const response = await AssetService.singleWithdraw({
          assetUid: assetDetail.assetUid,
          project: assetDetail.project,
          recipient: values.recipient,
          value: values.value,
          code: values.code
        })

        // mutate([MasterWalletService.getWithdrawals(), config])
        handleMutate()
        handleCloseModal()
        resetForm()

        message.success('Withdrawal request submitted')
      } catch (e) {
        handleError(e)

        return
        // Temporary default error message set to REQUEST_ERROR
        // return Modal.error({
        //   title:
        //     'An error occurred while submitting the request. Please contact support if this issue continues.'
        // })
      }
    }

    if (values.method === 'mass') {
      let error = stepForm.getFieldValue('error')
      if (error != null) {
        handleError(error)

        return
        // return Modal.error({
        //   title:
        //     'An error occurred while submitting the request. Please contact support if this issue continues.'
        // })
      }

      try {
        const response = await AssetService.massWithdraw({
          assetUid: assetDetail.assetUid,
          project: assetDetail.project,
          code: values.code,
          fileUuid: stepForm.getFieldValue('fileUuid')
        })

        handleMutate()
        handleCloseModal()
        resetForm()

        message.success('Withdrawal request submitted')
      } catch (e) {
        handleError(e)

        return
        // Temporary default error message set to REQUEST_ERROR
        // return Modal.error({
        //   title:
        //     'An error occurred while submitting the request. Please contact support if this issue continues.'
        // })
      }
    }
  }

  useEffect(() => {
    if (stepForm) {
      stepForm.setFieldsValue({ asset: assetDetail.assetUid })
    }
  }, [stepForm, assetDetail])
  return (
    <>
      <Modal
        className={styles.modalView}
        title={
          <div>
            <Typography.Title level={3} align="center">{`Withdraw - ${assetDetail.symbol}`}</Typography.Title> <br />
            <Typography.Text type="secondary">
              <Space>
                <InfoCircleOutlined />
                Please ensure there is sufficient balance in your masterwallet to pay for network
                fee.
              </Space>
            </Typography.Text>
          </div>
        }
        visible={visible}
        maskClosable={false}
        closable={false}
        width={700}
        centered
        footer={[
          <Button
            key="back"
            onClick={() => {
              handleCloseModal()
              resetForm()
            }}
          >
            Cancel
          </Button>,
          currentStep > 0 && (
            <Button key="previous" style={{ margin: '0 5px' }} onClick={() => prev()}>
              Go Back
            </Button>
          ),
          currentStep < steps.length - 1 && (
            <Button key="next" type="primary" onClick={handleSubmitMethod}>
              Next
            </Button>
          ),
          currentStep === steps.length - 1 && (
            <Button key="done" type="primary" onClick={() => stepForm.submit()}>
              Confirm
            </Button>
          )
        ]}
      >
        <Form
          form={stepForm}
          preserve={false}
          onFinish={handleSubmitWithdrawal}
          {...layout}
          validateMessages={validateMessages}
          initialValues={{
            method: 'single',
            txFee: assetDetail.transactionFee,
            asset: assetDetail.assetUid
          }}
        >
          <div style={{ alignContent: "center" }}>
            <Steps current={currentStep} responsive size='small'>
              {steps.map((item) => (
                <Step key={item.title} title={item.title} />
              ))}
            </Steps>
          </div>
          {steps.map((item) => (
            <div
              key={`withdraw_step_${item.step}`}
              className={item.step !== currentStep + 1 ? styles.hidden : styles.stepsContent}
            >
              {item.content}
            </div>
          ))}
        </Form>
      </Modal>
    </>
  )
}

export default WithdrawModal
