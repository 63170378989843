import React, { useMemo, useState } from 'react'
import { isMobile } from 'react-device-detect';
import { Spin, Typography, List, Row, Col, Space, Tooltip } from 'antd'
import {
  LinkOutlined,
  CheckCircleTwoTone,
  LoadingOutlined,
  InfoCircleOutlined,
  CopyOutlined,
  CheckOutlined
} from '@ant-design/icons'
import useSWR from 'swr'
import { useInterval, useToggle } from 'react-use'
import { useSnapshot } from 'valtio'

import styles from '../../index.less'
import MasterWalletService from '../../../../network/masterwallet'
import { serialize } from '../../../../network/request'
import masterwalletStore from '../../../../store/masterwallet'

const { Text, Title } = Typography;
const openInNewTab = (url) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
}

function truncateLongAddress(address, keep = 8) {
  let first = address.substring(0, keep)
  let last = address.substring(address.length - keep, address.length)

  return `${first}...${last}`
}

const CopyableLink = ({ value }) => {
  const [duration, setDuration] = useState(null)
  const [showSuccess, toggleSuccess] = useToggle(false)

  useInterval(() => {
    if (duration != null) {
      toggleSuccess()
    }

    setDuration(null)
  }, [duration])

  return (
    <Tooltip title={showSuccess ? 'Copied' : 'Copy'}>
      <a
        onClick={() =>
          navigator.clipboard.writeText(value).then(
            function () {
              toggleSuccess()
              setDuration(2000)
            },
            function (err) {
              console.error('Async: Could not copy text: ', err)
            }
          )
        }
      >
        {showSuccess ? <CheckOutlined /> : <CopyOutlined />}
      </a>
    </Tooltip>
  )
}

const MasterWalletTransactionsList = ({
  type,
  assetDetail,
  shouldMutate,
  currentPage,
  pageSize
}) => {
  const [isLoading] = useToggle(false)

  const { search } = useSnapshot(masterwalletStore)

  const config = useMemo(
    () => ({
      page: search ? 1 : currentPage,
      limit: pageSize,
      type,
      project: assetDetail.project,
      assetUid: assetDetail.assetUid,
      hash: search
    }),
    // use shouldMutate to force revalidate
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [assetDetail, currentPage, pageSize, type, shouldMutate, search]
  )
  const url = useMemo(() => {
    if (type === 'deposits') {
      return MasterWalletService.getDeposits
    } else {
      return MasterWalletService.getWithdrawals
    }
  }, [type])

  const { data: response, error } = useSWR(serialize(url, config))
  if (error) {
    return <Typography.Text>{error.response}</Typography.Text>
  }

  if (!response) {
    return <Spin />
  }

  const { transactions } = response.messageBody

  const transformedTransactions =
    transactions?.map((tx) => {
      const date = new Date(tx.createdAt * 1000)
      const formattedTime = new Intl.DateTimeFormat('en-GB', {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
      })
        .format(date)
        .toString()

      const formattedDate = new Intl.DateTimeFormat('en-GB', {
        month: 'numeric',
        day: 'numeric',
        year: 'numeric'
      })
        .format(date)
        .toString()

      return { ...tx, formattedTime, formattedDate }
    }) ?? []

  return (
    <List
      size="small"
      loading={isLoading}
      itemLayout="horizontal"
      dataSource={transformedTransactions}
      renderItem={(item) => (
        <List.Item style={{padding: "8px 0px"}}>
          <List.Item.Meta
            description={
              <Row gutter={(24, 12)} align="middle" style={{ rowGap: 5 }}>
                <Col xl={{ span: 1, order: 1 }} xs={{ span: 24, order: 1 }} align="center">
                  {item.status === 'SUCCESS' ? (
                    <CheckCircleTwoTone twoToneColor="#52c41a" className={styles.icons} />
                  ) : (
                    <LoadingOutlined style={{ color: "#faad14" }} className={styles.icons} spin />
                  )}
                </Col>
                <Col xl={{ span: 2, order: 2 }} xs={{ span: 24, order: 2 }} align="center">
                  {isMobile ? (
                    <><>{`${item.formattedTime} (${item.formattedDate})`}</></>
                  ) : (
                    <>{item.formattedTime} <br /> {item.formattedDate}</>
                  )}
                </Col>
                <Col xl={{ span: 7, order: 3 }} xs={{ span: 24, order: 4 }} align="center">
                  Txid:<br />
                  {item.hash && item.url ? (
                    <div className={styles.codeFlex}>
                      <a style={{ padding: 0 }} onClick={() => openInNewTab(item.url)}>
                        <code><Tooltip title={item.status === 'SUCCESS' ? `View on explorer` : "View on explorer (waiting for block confirmation)"}>{truncateLongAddress(item.hash, 11)}</Tooltip><CopyableLink value={item.hash} /></code>
                      </a>

                    </div>
                  ) : (
                    <Tooltip title="Your withdrawal is being reviewed or being broadcasted on the blockchain."><Text code>Processing</Text></Tooltip>
                  )}
                </Col>
                <Col xl={{ span: 7, order: 4 }} xs={{ span: 24, order: 4 }} align="center">
                  {`${item.platformFee ? "From" : "To"} Address:`}<br />
                  <div className={styles.codeFlex}>
                    <code><Tooltip title={item.platformFee ? item.fromAddress : item.toAddress}>{truncateLongAddress(item.platformFee ? item.fromAddress : item.toAddress, 11)}</Tooltip><CopyableLink value={item.platformFee ? item.fromAddress : item.toAddress} /></code>
                  </div>
                </Col>
                <Col xl={{ span: 7, order: 5 }} xs={{ span: 24, order: 5 }} align="center">
                  <Text level={5} type={type === 'deposits' ? "success" : "danger"} strong>{type === 'deposits' ? '' : '-'}{item.value}</Text>
                  {" "}{assetDetail.symbol}
                </Col>
              </Row>
            }
          />
          <div>
            <Tooltip
              title={
                <div>
                  {item.platformFee && (
                    <Typography.Text style={{ color: 'white', display: 'block' }}>
                      Platform Fee: {item.platformFee}
                    </Typography.Text>
                  )}
                  <Typography.Text style={{ color: 'white' }}>
                    Network Fee: {item.transactionFee} {assetDetail.symbol}
                  </Typography.Text>
                </div>
              }
            >
              <InfoCircleOutlined />
            </Tooltip></div>
        </List.Item >
      )}
    />
  )
}

export default MasterWalletTransactionsList
