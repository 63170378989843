import React, { useState } from 'react'
import { Route } from 'react-router-dom'
import { PageContainer } from '@ant-design/pro-layout'
import { useBoolean, useCounter, useToggle } from 'react-use'
import { Spin, Card, Col, Row, Pagination, Typography, Button, message, Modal, Space } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import useSWR from 'swr'

import styles from './index.less'

import ErrorPage from '../error'
import ProjectCard from './components/ProjectCard'
import ModalForm from '../../components/ModalForm'
import ProjectService from '../../network/project'

const errorMessage = (msg) => {
  return { title: 'Could not create API Key.', content: <>{msg}</> }
}

const ProjectPage = () => {
  const [currentPage, { inc, dec, set }] = useCounter(1, null, 1)
  const [pageSize] = useState(10)
  const [isModalVisible, toggle] = useToggle(false)
  const [errorModal, contextHolder] = Modal.useModal()

  const { data: response, error } = useSWR(ProjectService.get)

  if (error) {
    console.log(error)
    return (
      <PageContainer>
        <ErrorPage error={error} />
      </PageContainer>
    )
  }
  if (!response) {
    return (
      <PageContainer>
        <Spin />
      </PageContainer>
    )
  }

  const { projects } = response.messageBody
  const { details, subwalletsCreated } = projects

  //   ToDo: use swr-sync-storage for persistent cache
  //   const userData = {
  //     ...JSON.parse(localStorage.getItem('user_data')),
  //     projects
  //   }
  //   localStorage.setItem('user_data', JSON.stringify(userData))

  //   const indexOfLastProject = currentPage * pageSize
  //   const indexOfFirstProject = indexOfLastProject - pageSize
  //   const currentProjects = details.slice(indexOfFirstProject, indexOfLastProject)

  const toggleModal = (state) => {
    if (state) {
      toggle(state)
    } else {
      toggle()
    }
  }

  const onPageChange = (page) => {
    set(page)
  }

  const handleSubmit = async (values) => {
    // setIsLoading(true)

    try {
      const res = await ProjectService.create({ label: values.label })
      //   setIsLoading(false)
      toggleModal(false)
      message.success(`'${res.data.messageBody.label}' successfully created.`)
    } catch (error) {
      console.log(error)
      // setIsLoading(false)
      toggleModal(false)
      errorModal.error(errorMessage(error.message))
    }
  }

  return (
    <PageContainer>
      <Typography.Title level={5}>Overview</Typography.Title>

      <Row gutter={[24, 32]}>
        <Col xs={24} xl={8}>
          <Card title="Projects" bordered={false} extra={projects.count} className={styles.fillRow}>
            <Row gutter={[16, 8]}>
              <Col span={12}>Active</Col>
              <Col span={12} align="right">
                {projects.active}
              </Col>
              <Col span={12}>Disabled</Col>
              <Col span={12} align="right">
                {projects.count - projects.active}
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs={24} xl={16}>
          <Card title="Subwallets Created" bordered={false} className={styles.fillRow}>
            <Row gutter={16}>
              <Col span={8}>
                <h1 className={styles.bottomMargin}>{subwalletsCreated['1days']}</h1>
                <Typography.Text type="secondary">Today</Typography.Text>
              </Col>
              <Col span={8}>
                <h1 className={styles.bottomMargin}>{subwalletsCreated['7days']}</h1>
                <Typography.Text type="secondary">7 Days</Typography.Text>
              </Col>
              <Col span={8}>
                <h1 className={styles.bottomMargin}>{subwalletsCreated['30days']}</h1>
                <Typography.Text type="secondary">30 Days</Typography.Text>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={24}></Col>
      </Row>

      <Row gutter={[24, 12]}>
        <Col span={8}>
          <Space>
            <Typography.Title level={5} className={styles.inlineTitle}>
              Projects
            </Typography.Title>
            <Typography.Text type="secondary">({projects.count}/5)</Typography.Text>
          </Space>
        </Col>
        <Col span={16} align="right">
          <Space>
            <Typography.Link href="#">How to use API Keys?</Typography.Link>
            <Button type="primary" icon={<PlusOutlined />} onClick={() => toggleModal()}>
              Create API Key
            </Button>
          </Space>
        </Col>

        {/* ToDo: use online pagination */}
        {details?.length > 0 ? (
          details.map((project) => (
            <Col span={24}>
              <ProjectCard key={project.apiKey} project={project} />
            </Col>
          ))
        ) : (
          <Col span={24}>
            <Typography.Text type="secondary">No Current Projects.</Typography.Text>
          </Col>
        )}

        {/* {details.length > 0 ? (
          currentProjects.map((project) => <ProjectCard key={project.apiKey} project={project} />)
        ) : (
          <Col span={24}>
            <Typography.Text type="secondary">No Current Projects.</Typography.Text>
          </Col>
        )} */}

        <Col span={24} align="center">
          <Pagination
            onChange={onPageChange}
            defaultCurrent={1}
            total={details.length}
            pageSize={pageSize}
            hideOnSinglePage={true}
          />
        </Col>
      </Row>
      {contextHolder}
      {/* <ModalForm
        formTitle="Create API Key"
        inputName="label"
        inputLabel="Project Name"
        inputPlaceholder="Please enter the name of the Project"
        handleCancel={() => toggleModal(false)}
        handleSubmit={handleSubmit}
        visible={isModalVisible}
        // loading={isLoading}
      /> */}
    </PageContainer>
  )
}

export default ProjectPage
