import React from 'react'
import { PageContainer } from '@ant-design/pro-layout'
import { Spin, Col, Row } from 'antd'
import useSWR from 'swr'

// import styles from './index.less'
// import { FeesTable } from './components/DashboardTables'
import ErrorPage from '../error'
import AssetList from './components/AssetList'
import ProjectList from './components/ProjectList'
// import ProjectService from '../../network/project'
import AssetService from '../../network/asset'

const DashboardPage = () => {
  // const { data: response, error } = useSWR(ProjectService.get)
  const { data: assetResponse, error: assetError } = useSWR(AssetService.get)

  if (assetError) {
    console.log(assetError)
    return (
      <PageContainer>
        <ErrorPage error={assetError} />
      </PageContainer>
    )
  }
  if (!assetResponse) {
    return (
      <PageContainer>
        <Spin />
      </PageContainer>
    )
  }

  // const { projects } = response.messageBody
  const { assets } = assetResponse.messageBody

  return (
    <PageContainer>
      <Row gutter={[24, 32]}>
        {/* Projects */}
        {/* <Col xs={24} xl={8}>
          <Card
            title={
              <div>
                <ProjectOutlined className={styles.titleIcon} />
                <Typography.Title level={4} className={styles.inlineTitle}>
                  Projects
                </Typography.Title>
              </div>
            }
            bordered={false}
            extra={
              <div>
                <h1 className={styles.extra} align="right">
                  {projects.count}
                </h1>
                <Typography.Text type="secondary">Total</Typography.Text>
              </div>
            }
            className={styles.fillRow}
          >
            <Row gutter={[16, 8]}>
              <Col span={12}>
                <LockOutlined />
                <nobr> Disabled</nobr>
              </Col>
              <Col span={12} align="right">
                {projects.count - projects.active}
              </Col>
            </Row>
          </Card>
        </Col> */}

        {/* Subwallets */}
        {/* <Col xs={24} xl={8}>
          <Card
            title={
              <div>
                <WalletOutlined className={styles.titleIcon} />
                <Typography.Title level={4} className={styles.inlineTitle}>
                  Subwallets
                </Typography.Title>
              </div>
            }
            bordered={false}
            extra={
              <div>
                <h1 className={styles.extra} align="right">
                  {projects.subwalletsCreated['1days']}
                </h1>
                <Typography.Text type="secondary">Created Today</Typography.Text>
              </div>
            }
            className={styles.fillRow}
          >
            <Row gutter={[16, 8]}>
              <Col span={12}>
                <CalendarOutlined />
                <nobr> Last login time</nobr>
              </Col>
              <Col span={12} align="right">
                {new Intl.DateTimeFormat('en-GB', {
                  month: 'long',
                  day: 'numeric',
                  year: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                  second: 'numeric'
                })
                  .format(new Date())
                  .toString()}
              </Col>
            </Row>
          </Card>
        </Col> */}

        {/* Total Crypto Balance */}
        {/* <Col xs={24} xl={8}>
          <Card
            title={
              <div>
                <DollarOutlined className={styles.titleIcon} />
                <Typography.Title level={4} className={styles.inlineTitle}>
                  Total Crypto Balance
                </Typography.Title>
              </div>
            }
            bordered={false}
            className={styles.fillRow}
          >
            <Row gutter={[16, 8]}>
              <Col span={24} align="right">
                <Statistic title="~ USD" value={112893} precision={2} />
              </Col>
            </Row>
          </Card>
        </Col> */}

        {/* Subwallets Created */}
        {/* <Col xs={24} xl={24}>
            <Card
              title={
                <Typography.Title level={4} className={styles.inlineTitle}>
                  Subwallets Created
                </Typography.Title>
              }
              bordered={false}
              className={styles.fillRow}
              extra={
                <Select
                  defaultValue="subwallets_24Hrs"
                  style={{ width: 150 }}
                  onChange={this.handleSelect}
                >
                  <Option value="subwallets_24Hrs">Last 24 Hours</Option>
                  <Option value="subwallets_30Days">Last 30 Days</Option>
                  <Option value="subwallets_12Months">Last 12 Months</Option>
                </Select>
              }
            >
              <SubwalletsChart />
            </Card>
          </Col> */}

        {/* Created Projects */}
        {/* <Col xs={24} xl={16}>
            <Card
              title={
                <Typography.Title level={4} className={styles.inlineTitle}>
                  Created Projects
                </Typography.Title>
              }
              bordered={false}
              className={styles.fillRow}
              extra={
                <Select
                  defaultValue="projects_24Hrs"
                  style={{ width: 150 }}
                  onChange={this.handleSelect}
                >
                  <Option value="projects_24Hrs">Last 24 Hours</Option>
                  <Option value="projects_30Days">Last 30 Days</Option>
                  <Option value="projects_12Months">Last 12 Months</Option>
                </Select>
              }
            >
              <ProjectsChart />
            </Card>
          </Col> */}

        <Col xs={24}>
          <ProjectList />
        </Col>

        <Col xs={24}>
          <AssetList assets={assets} />
        </Col>
      </Row>
    </PageContainer>
  )
}

export default DashboardPage
