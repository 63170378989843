import _ from 'lodash'
import queryString from 'query-string'
import { useLocation } from 'react-router'

export const filterQuery = (filters) => {
  let filterString = ''

  if (!_.isEmpty(filters)) {
    let index = 0
    _.forEach(filters, function (value, key) {
      if (value) {
        let str = ''
        if (index === 0) {
          str += '?'
        } else {
          str += '&'
        }

        if (Array.isArray(value)) {
          let obj = {}
          obj[key] = value
          str += `${queryString.stringify(obj, { arrayFormat: 'bracket' })}`
        } else {
          str += `${key}=${value}`
        }

        filterString += str
        index++
      }
    })
  }

  return filterString
}

export function useQuery() {
  return new URLSearchParams(useLocation().search)
}
