import React from 'react'
import { PageContainer } from '@ant-design/pro-layout'
import { Card, Descriptions, Button, Typography, Row, Col } from 'antd'
import useSWR from 'swr'
import { useTranslation } from 'react-i18next'
import { useSnapshot } from 'valtio'
import moment from 'moment'

import authStore from '../../store/auth'
import { Link } from 'react-router-dom'

const AccountPage = () => {
  const { t } = useTranslation(['common'])
  const { profile } = useSnapshot(authStore)

  return (

    <Card style={{ borderRadius: 20, boxShadow: "5px 8px 24px 5px rgba(208, 216, 243, 0.6)", padding: "50px 0px 100px 0px" }}>
      <div align="center">
        <Row gutter={[24, 12]} style={{ display: 'block', maxWidth: 500 }}>
          <Col lg={{ span: 24, order: 1 }} xs={{ span: 24, order: 1 }} align="center">
            <Descriptions title="User Info" layout="horizontal" column={1} bordered>
              <Descriptions.Item label="Email:">{profile.email}</Descriptions.Item>
              <Descriptions.Item label="Last Active:">{profile.lastActive != null
                ? moment.unix(profile.lastActive).format('yyyy/MM/DD hh:mm:ss a')
                : '-'}</Descriptions.Item>
              <Descriptions.Item label="Password:"><Link to="/account/change-password">
                <Button>Change Password</Button>
              </Link></Descriptions.Item>
            </Descriptions>

          </Col>
        </Row>
      </div>
    </Card>
  )
}

export default AccountPage
