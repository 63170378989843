import client from './request'

const get = `/user/subwallet`

const getDeposit = `/user/subwallet/deposits`

const SubWalletService = {
  get,
  getDeposit
}

export default SubWalletService
