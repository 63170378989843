import React, { useMemo, useState } from 'react'
import { Card, Pagination, Typography, Space, Row, Col, Spin } from 'antd'
import useSWR from 'swr'
import {CloseCircleFilled} from '@ant-design/icons'

import AssetService from '../../../../network/asset'
import { serialize } from '../../../../network/request'

const Transactions = ({
  form,
  assetDetail,
  withdrawMethod,
  setHasError,
  currentPage,
  pageSize
}) => {
  const file = form.getFieldValue('fileUuid')

  const { data: response } = useSWR(
    serialize(AssetService.getUpload, {
      assetUid: assetDetail.assetUid,
      fileUuid: file,
      limit: pageSize,
      page: currentPage,
      project: assetDetail.project
    })
  )

  const transactions = response?.messageBody?.transactions

  return (
    <>
      {transactions?.map((transaction) => {
        return (
          <Row>
            <Col span={16}>{transaction.address}</Col>
            <Col span={8} align="end">
              {transaction.amount}
            </Col>
          </Row>
        )
      })}
    </>
  )
}

const MassConfirmation = (props) => {
  const { form, assetDetail, withdrawMethod, setHasError } = props
  const file = form.getFieldValue('fileUuid')
  const [pageSize] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)

  const { data: response, error } = useSWR(
    file != null
      ? serialize(AssetService.getUpload, {
        assetUid: assetDetail.assetUid,
        fileUuid: file,
        limit: pageSize,
        page: 1,
        project: assetDetail.project
      })
      : null
  )

  if (error) {
    setHasError(true)
    form.setFieldsValue({ error })

    if (error.message === 'ERR_FILE_VALIDATION') {
      return (
        <Space direction="vertical" style={{ minWidth: '50%' }}>
          <Typography.Text>Invalid file uploaded</Typography.Text>
        </Space>
      )
    }

    if (error.message === 'EXCEEDED_MAX_WITHDRAW_COUNT') {
      return (
        <Space direction="vertical" style={{ minWidth: '50%' }}>
          <Typography.Text>Only a maximum of 500 rows are allowed in CSV</Typography.Text>
        </Space>
      )
    }

    if (error.message === 'INSUFFICIENT_BALANCE') {
      return (
        <Space direction="vertical" style={{ minWidth: '50%' }}>
          <CloseCircleFilled style={{fontSize:40, color:"#ff7875"}}/>
          <Typography.Title level={3}>Insufficient Balance</Typography.Title>

          <Row>
            <Col>
              <Typography.Text type="secondary">Network Fee:</Typography.Text>
            </Col>
            <Col align="right" flex="auto">
              <Typography.Text style={{ marginLeft: 8 }}>
                {error.body?.totalTransactionFee}
              </Typography.Text>
            </Col>
          </Row>
          <Row>
            <Col>
              <Typography.Text type="secondary">Amount:</Typography.Text>
            </Col>
            <Col align="right" flex="auto">
              <Typography.Text style={{ marginLeft: 8 }}>{error.body?.totalAmount}</Typography.Text>
            </Col>
          </Row>
          <Row>
            <Col>
              <Typography.Text type="secondary">Available Balance:</Typography.Text>
            </Col>
            <Col align="right" flex="auto">
              <Typography.Text style={{ marginLeft: 8 }} type="danger">
                {error.body?.balance}
              </Typography.Text>
            </Col>
          </Row>
        </Space>
      )
    }
  } else {
    setHasError(false)
    form.setFieldsValue({ error: null })
  }

  if (file && !response) return <Spin />

  const { meta, transactions } = response?.messageBody

  const onPageChange = (page) => {
    setCurrentPage(page)
  }

  return (

    <Space direction="vertical" style={{ width: '100%' }}>
      <Card style={{ width: "100%" }}>
      <Transactions {...props} currentPage={currentPage} pageSize={pageSize} />
      </Card>
      <div style={{ display: 'none' }}>
        {currentPage <= meta?.transactionCount / pageSize && (
          <Transactions {...props} currentPage={currentPage + 1} pageSize={pageSize} />
        )}
      </div>

      {transactions?.length > 0 && (
        <div align="right">
          <Pagination
            onChange={onPageChange}
            defaultCurrent={1}
            total={meta?.transactionCount}
            pageSize={pageSize}
          />
        </div>
      )}

      <Card style={{ width: "100%" }}>
        <Space direction="vertical" size={0} align="start">
          <Typography.Text>No of addresses: {meta.transactionCount}</Typography.Text>
          <Typography.Text>Total amount: {meta.totalAmount}</Typography.Text>
          <Typography.Text>Total network fee: {meta.totalTransactionFee}</Typography.Text>
        </Space>
      </Card>

      <div style={{ paddingTop: 20 }} />
    </Space>
  )
}

export default MassConfirmation
