import React, { useMemo, useState } from 'react'
import Blockies from 'react-blockies';
import { isMobile } from 'react-device-detect';
import { Spin, Typography, List, Row, Col, Button, Tooltip } from 'antd'
import { EyeOutlined, WalletOutlined, CheckOutlined, CopyOutlined } from '@ant-design/icons'
import { useInterval, useToggle } from 'react-use'
import useSWR from 'swr'
import { useSnapshot } from 'valtio'

import styles from '../../index.less'
import SubWalletService from '../../../../network/subwallet'
import { serialize } from '../../../../network/request'
import assetStore from '../../../../store/asset'
import subwalletStore from '../../../../store/subwallet'

const SubwalletDepositHistoriesList = ({ assetDetail, setCurrentTab, currentPage, pageSize }) => {
  const [isLoading, toggleLoading] = useToggle(false)
  const { search } = useSnapshot(subwalletStore)

  const { data: response, error } = useSWR(
    serialize(SubWalletService.get, {
      page: search ? 1 : currentPage,
      limit: pageSize,
      project: assetDetail.project,
      assetUid: assetDetail.assetUid,
      address: search
    })
  )
  if (error) {
    console.log(error)
    return <Typography.Text>{error.response}</Typography.Text>
  }

  if (!response) {
    return <Spin />
  }

  const { results } = response.messageBody

  const subwallets = results?.map((subwallet) => {
    const date = new Date(subwallet.createdAt * 1000)
    const formattedTime = new Intl.DateTimeFormat('en-GB', {
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric'
    })
      .format(date)
      .toString()

    const formattedDate = new Intl.DateTimeFormat('en-GB', {
      month: 'numeric',
      day: 'numeric',
      year: 'numeric'
    })
      .format(date)
      .toString()

    return { ...subwallet, formattedTime, formattedDate }
  })

  function truncateLongAddress(address, keep = 8) {
    let first = address.substring(0, keep)
    let last = address.substring(address.length - keep, address.length)

    return `${first}...${last}`
  }

  const CopyableLink = ({ value }) => {
    const [duration, setDuration] = useState(null)
    const [showSuccess, toggleSuccess] = useToggle(false)

    useInterval(() => {
      if (duration != null) {
        toggleSuccess()
      }

      setDuration(null)
    }, [duration])

    return (
      <Tooltip title={showSuccess ? 'Copied' : 'Copy'}>
        <a
          onClick={() =>
            navigator.clipboard.writeText(value).then(
              function () {
                toggleSuccess()
                setDuration(2000)
              },
              function (err) {
                console.error('Async: Could not copy text: ', err)
              }
            )
          }
        >
          {showSuccess ? <CheckOutlined /> : <CopyOutlined />}
        </a>
      </Tooltip>
    )
  }

  return (
    <List
      size="small"
      loading={isLoading}
      itemLayout="horizontal"
      dataSource={subwallets}
      renderItem={(item) => (
        <List.Item style={{ padding: "8px 0px" }}>
          <List.Item.Meta
            description={
              <Row gutter={(24, 12)} align="middle" style={{ rowGap: 5 }}>
                <Col lg={{ span: 2, order: 1 }} xs={{ span: 24, order: 1 }} align="center">
                  {isMobile ? (
                    <><Typography.Text style={{ display: 'block' }}>
                      {`${item.formattedTime} (${item.formattedDate})`}
                    </Typography.Text></>
                  ) : (
                    <><Typography.Text style={{ display: 'block' }}>
                      {item.formattedTime}
                    </Typography.Text><Typography.Text>{item.formattedDate}</Typography.Text></>
                  )}
                </Col>

                <Tooltip title={`Identicon for Subwallet Address`}>
                  <Col lg={{ span: 1, order: 2 }} xs={{ span: 24, order: 2 }} align="center">
                    <Blockies seed={item.address} className={styles.identicons} />
                  </Col>
                </Tooltip>

                <Col lg={{ span: 7, order: 2 }} xs={{ span: 24, order: 2 }} align="center">
                  <Typography.Text type="secondary" style={{ display: 'block' }}>
                    <WalletOutlined />{" "}Subwallet Address
                  </Typography.Text>
                  <Typography.Text code>
                    <Tooltip title={item.address}>
                      {truncateLongAddress(item.address, 18)}
                    </Tooltip>
                    <CopyableLink value={item.address} />
                  </Typography.Text>
                </Col>

                <Col lg={{ span: 5, order: 3 }} xs={{ span: 24, order: 3 }} align="center">
                  <Tooltip title={`Total deposits received in subwallet (${item.address})`}>
                    <Typography.Text type="secondary" className={styles.subwalletAlignCenter}>
                      Total Received
                    </Typography.Text>
                    <Typography.Text type="success" className={styles.subwalletAlignCenter}>
                      {item.totalDeposited.toLocaleString()} {assetDetail.symbol}
                    </Typography.Text>
                  </Tooltip>
                </Col>

                <Col lg={{ span: 5, order: 4 }} xs={{ span: 24, order: 4 }} align="center">
                  <Tooltip title="Total network fee and platform fee incurred">
                    <Typography.Text type="secondary" className={styles.subwalletAlignCenter}>
                      Total Fees Incurred
                    </Typography.Text>
                    <Typography.Text type="danger" className={styles.subwalletAlignCenter}>
                      {Number(item.feesTotal) !== 0 ? '-' : ''}{item.feesTotal.toLocaleString()} {assetDetail.symbol}
                    </Typography.Text>
                  </Tooltip>
                </Col>

                <Col lg={{ span: 4, order: 5 }} xs={{ span: 24, order: 5 }} align="center">
                  <Tooltip title={`Number of deposits into subwallet (${item.address})`}>
                    <Typography.Text type="secondary" className={styles.subwalletAlignCenter}>
                      No. of Deposits
                    </Typography.Text>
                    <Typography.Text className={styles.subwalletAlignCenter}>
                      {item.depositCount}
                    </Typography.Text>
                  </Tooltip>
                </Col>
              </Row>
            }
          />
          <div>
            <Tooltip title={item.depositCount == 0 ? "No deposits in subwallet yet" : "View deposited transactions"}>
              <Button
                onClick={() => {
                  assetStore.filter = item.address
                  assetStore.filterType = 'address'
                  assetStore.search = Math.random()

                  setCurrentTab('deposit_histories')
                }}
                icon={<EyeOutlined />}
                disabled={item.depositCount == 0 ? true : false}
              >
              </Button>
            </Tooltip>
          </div>
        </List.Item>
      )}
    />
  )
}

export default SubwalletDepositHistoriesList
