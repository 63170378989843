import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { SWRConfig } from 'swr'
import { BrowserRouter } from 'react-router-dom'
import { PageLoading } from '@ant-design/pro-layout' // loading components from code split

import 'antd/dist/antd.css'
import './index.css'
import './index.less'
import App from './App'
import reportWebVitals from './reportWebVitals'

import { fetcher } from './network/request'

ReactDOM.render(
  <React.StrictMode>
    <SWRConfig value={{ fetcher, revalidateOnFocus: false, errorRetryCount: 3 }}>
      <BrowserRouter>
        <Suspense fallback={<PageLoading />}>
          <App />
        </Suspense>
      </BrowserRouter>
    </SWRConfig>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
