import React from 'react'
import { NavLink } from 'react-router-dom'
import { Card, Col, Row, Button, Typography, Tag } from 'antd'
import { CheckCircleOutlined, MinusCircleOutlined } from '@ant-design/icons'
import styles from '../index.less'

const ProjectCard = ({ project }) => {
  const date = new Date(project.createdAt * 1000)
  const formattedDateTime = new Intl.DateTimeFormat('en-GB', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric'
  })
    .format(date)
    .toString()

  return (
    <Card
      title={project.label}
      bordered={false}
      extra={
        project.isActive ? (
          <Tag icon={<CheckCircleOutlined />} color="success">
            Active
          </Tag>
        ) : (
          <Tag icon={<MinusCircleOutlined />} color="default">
            Inactive
          </Tag>
        )
      }
      className={styles.fillRow}
    >
      <Row gutter={8}>
        <Col span={10}>
          <Typography.Text type="secondary">Created At</Typography.Text>
          <br />
          {formattedDateTime}
        </Col>
        <Col span={8}>
          <Typography.Text type="secondary">Subwallets Created Today</Typography.Text>
          <br />
          {project.subwalletsCreatedToday}
        </Col>
        <Col span={6} align="right">
          <Typography.Text type="secondary">View API Key</Typography.Text>
          <br />
          <Button type="primary">
            <NavLink to={'/projects/'.concat(project.label)}>Check</NavLink>
          </Button>
        </Col>
      </Row>
    </Card>
  )
}

export default ProjectCard
