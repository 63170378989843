import React, { useState } from 'react'
import { Card, Image, Form, Upload, Typography, Input, InputNumber, Radio, Row, Col } from 'antd'
import { InfoCircleOutlined, InboxOutlined } from '@ant-design/icons'
import AssetService from '../../../../network/asset'
import BigNumber from "bignumber.js";
import sampleCsv from '../../../../sampleCsv.png'

const options = [
  { label: 'Single', value: 'single' },
  {
    label: 'Mass',
    value: 'mass'
  }
]

var countDecimals = function (value) {
  if ((value % 1) != 0)
    return value.toString().split(".")[1].length;
  return 0;
};

const WithdrawalMethod = ({ form, assetDetail }) => {
  const [method, setMethod] = useState('single')
  const [isDropped, setIsDropped] = useState(false);

  const onChange = (e) => {
    setMethod(e.target.value)
  }

  const toggleDrop = () => {
    setIsDropped(!isDropped)
  }


  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e
    }
    return e && e.fileList
  }

  const handleUpload = async (file) => {
    return new Promise(async (resolve) => {
      try {
        const response = await AssetService.uploadCsv(file)
        const fileUuid = response.data.messageBody.uuid
        form.setFieldsValue({ fileUuid: fileUuid })
        resolve(false)
      } catch (error) {
        resolve(false)
      }
    })
  }

  return (
    <Row gutter={[24, 12]} style={{ rowGap: 0 }}>
      <Col span={24}>
        <Form.Item
          name="method"
          rules={[{ required: true, message: 'Please pick an item!' }]}
          align="center"
        >
          <Radio.Group options={options} onChange={onChange} value={method} />
        </Form.Item>
      </Col>

      {/* <div>
        <Tooltip title={'Select "Mass" for payouts to more than one recipient'}>
          <InfoCircleOutlined />
        </Tooltip>
      </div> */}

      {method === 'single' ? (
        <Col span={24} align="left">
          <Form.Item name="recipient" label="Address" rules={[{ required: true },
          () => ({
            validator(_, recipient) {
              if (recipient.length < 30) {
                return Promise.reject(new Error('Please check recipient address.'))
              }
              return Promise.resolve()
            }
          })
          ]}>
            <Input
              disabled={method !== 'single'}
              placeholder="Enter recipient address here"
            />
          </Form.Item>

          <Form.Item label="Asset" required>
            <Form.Item
              name="asset"
              noStyle
              rules={[{ required: true }]}
              style={{ marginBottom: 4 }}
            >
              <Input disabled placeholder="Asset" />
            </Form.Item>
            <div style={{ display: 'flex', alignItems: 'center', paddingTop: 10 }}>
              <InfoCircleOutlined style={{ color: "#1890ff", fontSize: 15 }} />
              <Typography.Text type="secondary" style={{ marginLeft: 12, fontSize: '0.9em' }}>
                Please ensure that the withdrawal address supports the ETHEREUM network. You may
                lose your assets if the recipient wallet or platform does not support retrievals.
              </Typography.Text>
            </div>
          </Form.Item>

          <Form.Item label="Amount" required>
            <Form.Item
              name="value"
              noStyle
              rules={[
                { required: true, message: 'Please input desired withdrawal amount here.' },
                () => ({
                  validator(_, value) {
                    if (value > 0) {
                      if (countDecimals(value) > assetDetail.decimal) {
                        return Promise.reject(new Error(`Maximum decimal place for ${assetDetail.symbol} is ${assetDetail.decimal}`))
                      }

                      let maxWithdrawableAmount = (new BigNumber(assetDetail.balance.base).minus(new BigNumber(assetDetail.transactionFee))).toFixed()

                      let totalWithdrawalBN = new BigNumber(value).plus(new BigNumber(assetDetail.transactionFee))
                      if (totalWithdrawalBN.gt(new BigNumber(assetDetail.balance.base))) {
                        if (Number(maxWithdrawableAmount) < 0) {
                          return Promise.reject(new Error(`You do not have sufficient masterwallet balance for withdrawal.`))
                        }
                        
                        return Promise.reject(new Error(`To cover network fee of ${assetDetail.transactionFee} ${assetDetail.symbol} your maximum withdrawable amount is ${maxWithdrawableAmount} ${assetDetail.symbol}`))
                      }
                      return Promise.resolve()
                    }
                    return Promise.reject(new Error('The value needs to be more than 0.'))
                  }
                })
              ]}
              style={{ marginBottom: 4 }}
            >
              <InputNumber
                stringMode
                style={{ width: '100%' }}
                disabled={method !== 'single'}
                placeholder="Input withdrawal amount here."
              />
            </Form.Item>
            <div style={{ marginTop: 4 }}>
              <Typography.Text type="secondary" style={{ fontSize: '0.9em', display: 'block' }}>
                {`Available: ${assetDetail.balance.base} ${assetDetail.symbol}`}
              </Typography.Text>
              <Typography.Text type="secondary" style={{ fontSize: '0.9em', display: 'block' }}>
                {`Network Fee: ${assetDetail.transactionFee} ${assetDetail.symbol}`}
              </Typography.Text>
            </div>
          </Form.Item>
        </Col>
      ) : (
        <>
          <Col span={24} align="center">
            <div style={{ maxWidth: 250, paddingBottom: 20 }}>
              <Typography align="left">
                <Typography.Text type="secondary" style={{ fontSize: '0.9em', display: 'block' }}>
                  {`Available: ${assetDetail.balance.base} ${assetDetail.symbol}`}
                </Typography.Text>
                <Typography.Text type="secondary" style={{ fontSize: '0.9em', display: 'block' }}>
                  {`Network Fee: ${assetDetail.transactionFee} ${assetDetail.symbol} / Address`}
                </Typography.Text>
              </Typography>
            </div>
          </Col>
          <Col span={24}>
            <div style={{ margin: 0 }}>
              <Card style={{ borderRadius: 10, border: "1px solid #d9d9d9" }}>
                <Form.Item wrapperCol={{ offset: 0, span: 24 }}>
                  <Form.Item
                    name="file"
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                    noStyle
                    rules={[{ required: true, message: "Please upload a (.csv) file." }]}
                  >

                    <Upload.Dragger
                      beforeUpload={(file) => {
                        return handleUpload(file)
                      }}
                      name="files"
                      accept=".csv"
                      maxCount={1}
                      style={{ padding: 15 }}
                      onChange={toggleDrop}
                    >
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">Click or drag file to this area to upload</p>
                      <p className="ant-upload-hint">
                        Please make sure you have selected the appropriate asset type and verify all
                        withdrawal details before uploading.
                      </p>
                      <p className="ant-upload-hint">
                        Only (*.csv) file format is accepted.
                      </p>
                    </Upload.Dragger>
                  </Form.Item>
                </Form.Item>
                <Typography style={{ paddingBottom: 5, paddingTop: 15 }} align="center">
                  <Typography.Text type="secondary">Sample Csv (<a href="https://docs.multichain.info/faq/single-and-mass-withdrawals" target="_blank">more info</a>)</Typography.Text>
                </Typography>
                {!isDropped && (
                  <Image
                    src={sampleCsv}
                  />
                )}

              </Card>
            </div>
          </Col>
        </>
      )}
    </Row>
  )
}

export default WithdrawalMethod
