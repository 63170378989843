import React, { useState, useEffect } from 'react'
import { PageContainer } from '@ant-design/pro-layout'
import {
  Spin,
  Popover,
  Row,
  Col,
  Space,
  Tag,
  Card,
  Table,
  Menu,
  Dropdown,
  Button,
  Typography,
  Tooltip,
  message,
  Modal,
  Empty,
  notification
} from 'antd'
import {
  CheckCircleTwoTone,
  ExclamationCircleTwoTone,
  InfoCircleOutlined,
  KeyOutlined,
  MoreOutlined,
  PoweroffOutlined,
  SendOutlined,
  SettingOutlined,
  ToolOutlined,
  LinkOutlined,
  PlusCircleOutlined
} from '@ant-design/icons'
import Icon from '@ant-design/icons';
import useSWR from 'swr'
import { useToggle } from 'react-use'
import moment from 'moment'

import styles from '../index.less'
import ErrorPage from '../../error'
import ProjectService from '../../../network/project'
import ModalForm from '../../../components/ModalForm'

import { ReactComponent as projectsIcon } from "../../../database.svg";
import { ReactComponent as rocketIcon } from "../../../rocket.svg";
const ProjectsIcon = () => <Icon component={projectsIcon} />


const RocketIcon = () => <Icon component={rocketIcon} />

const errorMessage = (title, msg) => {
  return { title: title, content: <>{msg}</> }
}

const ProjectList = () => {
  const [isConfigureVisible, toggleConfigure] = useToggle(false)
  const [isCreateVisible, toggleCreate] = useToggle(false)
  const [isLoading, toggleLoading] = useToggle(false)
  const [webhookLoading, toggleWebhook] = useToggle(false)

  const [errorModal, contextHolder] = Modal.useModal()
  const [selectedLabel, setSelectedLabel] = useState(null)
  const [selectedWebhook, setSelectedWebhook] = useState(null)
  const [projectWebhookLoading, setWebhookLoadingProject] = useState(null)


  useEffect(() => {
    toggleConfigure(selectedLabel != null || selectedWebhook != null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLabel, selectedWebhook])

  const { data: response, error, mutate } = useSWR(ProjectService.get)
  if (error) {
    return (
      <PageContainer>
        <ErrorPage error={error} />
      </PageContainer>
    )
  }
  if (!response) {
    return (
      <PageContainer>
        <Spin />
      </PageContainer>
    )
  }

  const { projects } = response.messageBody
  const { details } = projects

  let disableCreateProjBtn = projects.count >= 5 ? true : false

  const handleCreate = async (values) => {
    toggleLoading(true)

    try {
      const res = await ProjectService.create({ label: values.label })
      toggleLoading(false)
      toggleCreate(false)
      message.success(`'${res.data.messageBody.label}' successfully created.`)
      mutate()
    } catch (error) {
      toggleLoading(false)
      toggleCreate(false)
      let msgResponse = "Please try again in a moment."
      if (error.message === "NO_SYMBOLS_ALLOWED") {
        msgResponse = "No spaces or special characters allowed, except for underscore (_)."
      } else if (error.message === "EXCEED_PROJECT_COUNT") {
        msgResponse = "Project creation limit reached, please contact support if you wish to create more projects."
      } else if (error.message === "LABEL_ALREADY_EXIST") {
        msgResponse = "You already have a project with the same label, please input a unique label name for your new project."
      } else if (error.message === "MAX_CHAR_LABEL") {
        msgResponse = "Project label must not exceed 100 characters."
      } else if (error.message === "REQUEST_ERROR") {
        msgResponse = "Please check your input."
      } else {
        msgResponse = error.message
      }
      errorModal.error(errorMessage('Error', msgResponse))
    }
  }

  const handleConfigure = async (values) => {
    toggleLoading(true)

    try {
      if (selectedLabel) {
        const res = await ProjectService.updateLabel({
          apiKey: values.apiKey,
          newLabel: values.label
        })
        setSelectedLabel(null)
      }

      if (selectedWebhook) {
        const res = await ProjectService.updateWebhook({
          project: values.apiKey,
          url: values.webhookUrl
        })
        setSelectedWebhook(null)
      }

      message.success('Updated succesfully')
      mutate()
      toggleLoading(false)
    } catch (error) {
      toggleLoading(false)
      setSelectedLabel(null)
      setSelectedWebhook(null)

      let msgResponse = "Please try again in a moment."
      if (error.message === "NO_SYMBOLS_ALLOWED") {
        msgResponse = "No spaces or special characters allowed, except for underscore (_)."
      } else if (error.message === "LABEL_ALREADY_EXIST") {
        msgResponse = "You already have a project with the same label, please input a unique label name for your new project."
      } else if (error.message === "MAX_CHAR_LABEL") {
        msgResponse = "Project label must not exceed 100 characters."
      } else if (error.message === "REQUEST_ERROR") {
        msgResponse = "Please check your input."
      } else {
        msgResponse = error.message
      }
      errorModal.error(errorMessage('Error', msgResponse))
    }
  }

  const openWebhookNotification = (title, url) => {
    const key = `open${Date.now()}`;

    notification.open({
      key,
      message: title,
      icon: <CheckCircleTwoTone style={{ fontSize: '20px' }} twoToneColor="#52c41a" />,
      description: `URL: "${url}" \n\nClick to view the sample notification sent.`,
      onClick: () => { window.open('https://docs.multichain.info/start/notify-url\#sample-notification-post-to-your-webhook-url', '_blank'); notification.close(key) },
    });
  };

  const handleStatus = async (values) => {
    try {
      const res = await ProjectService.updateStatus({
        apiKey: values.apiKey,
        isActive: values.isActive
      })
      message.success('Updated successfully')
      mutate()
    } catch (error) {
      errorModal.error(errorMessage('Failed to update project', error.message))
    }
  }

  const handleTest = async (values) => {
    toggleWebhook(true)
    setWebhookLoadingProject(values.apiKey)

    try {
      const res = await ProjectService.testWebhook({
        project: values.apiKey
      })
      openWebhookNotification("Webhook Notification Sent!", values.webhookUrl)
      toggleWebhook(false)
      setWebhookLoadingProject(null)
      mutate()
    } catch (error) {
      toggleWebhook(false)
      setWebhookLoadingProject(null)
      errorModal.error(errorMessage('Failed to test webhook', error.message))
    }
  }

  const KeyModal = ({ row }) => {
    return (
      <Card style={{ width: 350 }}>
        <p><Typography.Text type="secondary" style={{ fontSize: '0.9em' }}>
          Programmers may acquire keys on a separate account for development purposes so you do not need to share account access. Only use these keys once Multichain is integrated (production).
        </Typography.Text>
        </p>
        <Typography.Text type="secondary" style={{ fontSize: '0.8em', display: 'inline-block' }}>
          <KeyOutlined />
          API KEY
        </Typography.Text>
        <p>
          <Typography.Text code copyable>
            {row.apiKey}
          </Typography.Text>
        </p>
        <Typography.Text type="secondary" style={{ fontSize: '0.8em', display: 'inline-block' }}>
          <KeyOutlined />
          SECRET KEY
        </Typography.Text>
        <p>
          <Typography.Text code copyable>
            {row.secretKey}
          </Typography.Text>
        </p>
      </Card>
    )
  }

  const ExtraMenu = ({ row }) => {
    return (
      <Dropdown
        trigger="click"
        overlay={
          <Menu
            onClick={(e) => {
              if (e.key === 'label') {
                setSelectedLabel(row)
              }

              if (e.key === 'webhook') {
                setSelectedWebhook(row)
              }

              if (e.key === 'status') {
                handleStatus({
                  apiKey: row.apiKey,
                  isActive: !row.isActive
                })
              }

              if (e.key === 'test') {
                handleTest(row)
              }
            }}
          >
            <Menu.Item key="test" disabled={row.webhookUrl == null}>
              <SendOutlined />{" "}Send test notification</Menu.Item>
            <Menu.Item key="status"><PoweroffOutlined />{" "}{row.isActive ? 'Turn off project' : 'Turn on project'}</Menu.Item>
            {/* <Menu.Item key="delete">Delete</Menu.Item> */}
            <Menu.Item key="label"><SettingOutlined />{" "}Change project label</Menu.Item>
            <Menu.Item key="webhook"><ToolOutlined />{" "}Configure webhook</Menu.Item>
          </Menu>
        }
      >
        <Button style={{borderRadius:5}}>{(webhookLoading && projectWebhookLoading == row.apiKey) == true ? <Spin size="small" /> : <MoreOutlined />}</Button>
      </Dropdown>
    )
  }

  return (
    <>
      <Card
        title={
          <Row gutter={[0, 16]}>
            <Col>
              <Typography.Title level={4} style={{ margin: 0 }}>
                <ProjectsIcon />{" "}
                {`Projects (${projects.count} / 5)`}
              </Typography.Title>
              {projects.count == 0 ? (<Typography.Text type="secondary" style={{ display: 'block', margin: 0 }}>
                Create a project to begin
              </Typography.Text>) : (<Typography.Text type="secondary" style={{ display: 'block', margin: 0 }}>
                Create more projects if you own multiple products. <RocketIcon />
              </Typography.Text>)}
            </Col>
            <Col flex="auto" style={{ textAlign: 'right' }}>
              <Space>
                <Button href="https://docs.multichain.info" target="_blank" rel="noreferrer">
                  <LinkOutlined />
                  API Docs
                </Button>
                {projects.count !== 0 ? (<Tooltip title={disableCreateProjBtn ? "Contact support if you wish to increase project limit." : "Create a new project"}>
                  <Button type="primary" onClick={() => toggleCreate(true)} disabled={disableCreateProjBtn}>
                    <PlusCircleOutlined />
                    Create Project
                  </Button>
                </Tooltip>) : (" ")}
              </Space>
            </Col>
          </Row>
        }
        bordered={false}
        className={styles.fillRow}
      >
        {projects.count == 0 ?
          (
            <div style={{ padding: 50 }}>
              <Empty
                image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                imageStyle={{
                  height: 100,
                }}
                description={
                  <span>
                    Create a <a href="https://docs.multichain.info/start/creating-a-project" target="_blank">project</a> to begin integration.
                  </span>
                }
              >
                <Button type="primary" onClick={() => toggleCreate(true)} disabled={disableCreateProjBtn}>
                  <PlusCircleOutlined />
                  Create Project
                </Button>
              </Empty>
            </div>
          ) :
          (<Table
            size="small"
            scroll={{ x: 600 }}
            columns={[
              // {
              //   title: 'Projects',
              //   dataIndex: 'label',
              //   key: 'label',
              //   render: (value, row) => {
              //     return (
              //       <Space direction="vertical">
              //         <Space>
              //           <Typography.Text
              //             type="secondary"
              //             style={{ width: 70, display: 'inline-block' }}
              //           >
              //             Label
              //           </Typography.Text>
              //           <Typography.Text>{value}</Typography.Text>
              //           <Popover
              //             content={<KeyModal row={row} />}
              //             //   title="Title"
              //             trigger="click"
              //             //   visible={this.state.visible}
              //             //   onVisibleChange={this.handleVisibleChange}
              //           >
              //             <Tag>View Key</Tag>
              //           </Popover>
              //         </Space>
              //         <Space>
              //           <Typography.Text
              //             type="secondary"
              //             style={{ width: 70, display: 'inline-block' }}
              //           >
              //             Status
              //           </Typography.Text>
              //           {row.isActive ? (
              //             <Tag color="green">Active</Tag>
              //           ) : (
              //             <Tag color="red">Inactive</Tag>
              //           )}
              //         </Space>
              //         <Space>
              //           <Typography.Text
              //             type="secondary"
              //             style={{ width: 70, display: 'inline-block' }}
              //           >
              //             Subwallets
              //           </Typography.Text>
              //           <Typography.Text>{row.subwalletsCreatedTotal}</Typography.Text>
              //         </Space>
              //         <Space>
              //           <Typography.Text
              //             type="secondary"
              //             style={{ width: 70, display: 'inline-block' }}
              //           >
              //             Created On
              //           </Typography.Text>
              //           <Typography.Text>
              //             {moment.unix(row.createdAt).toLocaleString()}
              //           </Typography.Text>
              //         </Space>
              //         <Space>
              //           <Typography.Text
              //             type="secondary"
              //             style={{ width: 70, display: 'inline-block' }}
              //           >
              //             Notify URL
              //           </Typography.Text>
              //           <Tooltip title={row.webhookUrl ?? 'Weburl not set'}>
              //             {row.webhookUrl == null ? (
              //               <ExclamationCircleTwoTone twoToneColor="#eb2f96" />
              //             ) : (
              //               <C

              //             )}
              //           </Tooltip>
              //         </Space>
              //         <Space>
              //           <Typography.Text
              //             type="secondary"
              //             style={{ width: 70, display: 'inline-block' }}
              //           >
              //             Action
              //           </Typography.Text>
              //           <ExtraMenu row={row} />
              //         </Space>
              //       </Space>
              //     )
              //   },
              //   responsive: ['xs']
              // },
              {
                title: "Label",
                dataIndex: 'label',
                key: 'label',
                align: 'left',
                render: (value, row) => {
                  return (
                    <Space>
                      <Popover content={<KeyModal row={row} />} trigger="click">
                        <Tag><KeyOutlined style={{ color: "#faad14" }} />{" "}View Keys</Tag>
                      </Popover>
                      <Typography.Title level={5} style={{ color: "#1890ff", marginBottom: 0 }}>{value}</Typography.Title>
                    </Space>
                  )
                }
                // responsive: ['md']
              },
              {
                title: 'Status',
                dataIndex: 'isActive',
                key: 'isActive',
                align: 'center',
                render: (value) => {
                  if (value) {
                    return <Tag style={{ borderRadius: 15 }} color="green">Enabled</Tag>
                  } else {
                    return <Tag style={{ borderRadius: 15 }} color="orange">Disabled</Tag>
                  }
                }
                // responsive: ['md']
              },
              {
                title: 'Total Subwallets',
                dataIndex: 'subwalletsCreatedTotal',
                key: 'subwalletsCreatedTotal',
                align: 'center',
                render: (value) => {
                  return <Tooltip title="Total subwallets created across all asset's blockchain networks.">{value}</Tooltip>
                }
                // responsive: ['md']
              },
              {
                title: 'Created On',
                dataIndex: 'createdAt',
                key: 'createdAt',
                align: 'center',
                render: (value) => {
                  return moment.unix(value).format("MM/DD/YYYY hh:mm a").toLocaleString()
                }
                // responsive: ['md']
              },
              {
                title: 'Notify URL',
                dataIndex: 'webhookUrl',
                key: 'webhookUrl',
                align: 'center',
                render: (value) => {
                  return (
                    <Tooltip title={value ?? 'Webhook notify url not set'}>
                      {value == null ? (
                        <ExclamationCircleTwoTone style={{ fontSize: '20px' }} twoToneColor="red" />
                      ) : (
                        <CheckCircleTwoTone style={{ fontSize: '20px' }} twoToneColor="#52c41a" />
                      )}
                    </Tooltip>
                  )
                }
                // responsive: ['md']
              },
              {
                title: 'Last Configured',
                dataIndex: 'updatedAt',
                key: 'updatedAt',
                align: 'center',
                render: (value) => {
                  return (
                    <Tooltip title={`Time: ${moment.unix(value).format("hh:mm a").toLocaleString()}`}>
                      {moment.unix(value).format("LL").toLocaleString()}
                    </Tooltip>
                  )
                }
                // responsive: ['md']
              },
              {
                title: 'Action',
                dataIndex: 'action',
                key: 'action',
                align: 'center',
                render: (_, row) => {
                  return <ExtraMenu row={row} />
                }
                // responsive: ['md']
              }
            ]}
            dataSource={details}
            pagination={false}
          />)
        }
      </Card>

      {contextHolder}

      <ModalForm
        formTitle="Create Project"
        inputs={[
          {
            hidden: false,
            inputName: 'label',
            inputLabel: (
              <Space>
                <Typography.Text>Project Label</Typography.Text>
                <Tooltip title="Give a name to your project, no symbols is allowed.">
                  <InfoCircleOutlined />
                </Tooltip>
              </Space>
            ),
            inputPlaceholder: 'E.g. My_01Project',
            rules: [{ required: true }]
          }
        ]}
        handleCancel={() => toggleCreate(false)}
        handleSubmit={handleCreate}
        visible={isCreateVisible}
        buttonLabel="Create"
        loading={isLoading}
      />

      <ModalForm
        formTitle="Project Settings"
        inputs={[
          {
            hidden: true,
            inputName: 'apiKey'
          },
          {
            hidden: selectedLabel == null,
            inputName: 'label',
            inputLabel: (
              <Space>
                <Typography.Text>Project Label</Typography.Text>
                <Tooltip title="Give a name to your project, no symbols is allowed.">
                  <InfoCircleOutlined />
                </Tooltip>
              </Space>
            ),
            inputPlaceholder: 'E.g. My_01_Project'
          },
          {
            hidden: selectedWebhook == null,
            inputName: 'webhookUrl',
            inputLabel: (
              <Space>
                <Typography.Text>Notify URL</Typography.Text>
                <Tooltip title="A webhook notification will be sent to this url when a deposit is received. Click to read more.">
                  <a href="https://docs.multichain.info/start/notify-url" target="_blank"> <u><strong>(What's this?)</strong></u></a>
                </Tooltip>
              </Space>
            ),
            inputPlaceholder: 'E.g. https://mywebsite.com/multichain/webhook'
          }
        ]}
        handleCancel={() => {
          setSelectedLabel(null)
          setSelectedWebhook(null)
        }}
        handleSubmit={handleConfigure}
        visible={isConfigureVisible}
        initialValues={
          selectedWebhook != null
            ? {
              webhookUrl: selectedWebhook.webhookUrl,
              apiKey: selectedWebhook.apiKey
            }
            : selectedLabel != null
              ? {
                label: selectedLabel.label,
                apiKey: selectedLabel.apiKey
              }
              : null
        }
        buttonLabel="Save"
        loading={isLoading}
      />
    </>
  )
}

export default ProjectList
