import { LogoutOutlined, UserOutlined } from '@ant-design/icons'
import { Avatar, Menu, Spin } from 'antd'
import React from 'react'
import { useSnapshot } from 'valtio'

import HeaderDropdown from '../HeaderDropdown'
import styles from './index.less'
import auth, { logout } from '../../store/auth'
import { useHistory, useLocation } from 'react-router'

const AvatarDropdown = () => {
  const { profile } = useSnapshot(auth)
  const history = useHistory()
  const onMenuClick = (event) => {
    const { key } = event

    if (key === 'logout') {
      logout()
    } else if (key === 'account') {
      history.push('/account')
    }
  }

  const menuHeaderDropdown = (
    <Menu className={styles.menu} selectedKeys={[]} onClick={onMenuClick}>
      <Menu.Item key="account">
        <UserOutlined />
        Account
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="logout" danger>
        <LogoutOutlined />
        Logout
      </Menu.Item>
    </Menu>
  )

  // && profile.avatar
  return profile ? (
    <HeaderDropdown overlay={menuHeaderDropdown}>
      <span className={`${styles.action} ${styles.account}`}>
        <Avatar size="large" className={styles.avatar} icon={profile['email'].charAt(0).toUpperCase()} style={{ color: '#f56a00', backgroundColor: '#fde3cf' }} src={profile.avatar} alt="avatar" />
      </span>
    </HeaderDropdown>
  ) : (
    <span className={`${styles.action} ${styles.account}`}>
      <Spin
        size="small"
        style={{
          marginLeft: 8,
          marginRight: 8
        }}
      />
    </span>
  )
}

export default AvatarDropdown
