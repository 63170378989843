import { Route, Switch, Redirect } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { useSnapshot } from 'valtio'
import { Result, Button } from 'antd'
import useSWR from 'swr'

import './i18n'
import store, { checkAuthorization } from './store/auth'

import BasicLayout from './layouts/BasicLayout'
import UserLayout from './layouts/UserLayout'

import AuthPage from './pages/auth'
import ResetPasswordPage from './pages/auth/reset_password'
import ForgotPasswordPage from './pages/auth/forgot_password'
import DashboardPage from './pages/dashboard'
import ProjectPage from './pages/projects'
import ProjectDetailPage from './pages/projects/detail'
import AssetsPage from './pages/assets'
import VerificationPage from './pages/verification'
import AccountPage from './pages/account'
import ChangePasswordPage from './pages/account/change_password'

import AssetService from './network/asset'
import AnnouncementService from './network/announcement'
import assetStore from './store/asset'
import app from './store/app'
import { serialize } from './network/request'

const PrivateRoute = ({ children, roleLevel = [99], ...rest }) => {
  const { token, profile } = useSnapshot(store)

  // init common data
  const { data: response, error } = useSWR(AssetService.get)
  const { data: announcementRes, error: announcementError } = useSWR(
    serialize(AnnouncementService.get, { type: 'banner' })
  )

  if (response?.messageBody?.assets) {
    assetStore.list = response.messageBody.assets
  }

  if (announcementRes) {
    app.announcements = announcementRes.messageCode.result
  }

  if (error) {
    console.log('failed to fetch asset list')
    console.log(error)
  }

  if (announcementError) {
    console.log('failed to fetch announcement')
    console.log(announcementError)
  }

  return (
    <Route
      {...rest}
      render={({ location }) => {
        // master admin 0, super admin 1, admin 2, user 99
        let currentLevel = profile?.admin?.type ?? 99

        if (!roleLevel.includes(currentLevel)) {
          // no permission
          return (
            <Result
              status={403}
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Button type="primary">
                  <Link to="/">Go Login</Link>
                </Button>
              }
            />
          )
        }

        if (token != null) {
          return children
        }

        return (
          <Redirect
            to={{
              pathname: '/',
              state: { from: location }
            }}
          />
        )
      }}
    />
  )
}

const PublicRoute = ({ children, ...rest }) => {
  const { token, profile } = useSnapshot(store)

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (token == null) {
          return children
        }

        return (
          <Redirect
            to={{
              pathname: '/dashboard',
              state: { from: location }
            }}
          />
        )
      }}
    />
  )
}

const App = () => {
  return (
    <Switch>
      <PrivateRoute path="/dashboard" exact>
        <BasicLayout>
          <DashboardPage />
        </BasicLayout>
      </PrivateRoute>

      <PrivateRoute path="/projects" exact>
        <BasicLayout>
          <ProjectPage />
        </BasicLayout>

        <Route path="/projects/:label">
          <ProjectDetailPage />
        </Route>
      </PrivateRoute>

      <PrivateRoute path="/assets" exact>
        <BasicLayout>
          <AssetsPage />
        </BasicLayout>
      </PrivateRoute>

      <PrivateRoute path="/docs" exact>
        <BasicLayout>
          <p>Docs</p>
        </BasicLayout>
      </PrivateRoute>

      <PrivateRoute path="/contact" exact>
        <BasicLayout>
          <p>Contact</p>
        </BasicLayout>
      </PrivateRoute>

      <PrivateRoute path="/account">
        <BasicLayout>
          <Switch>
            <Route path="/account/change-password" exact>
              <ChangePasswordPage />
            </Route>

            <Route path="/account" exact>
              <AccountPage />
            </Route>
          </Switch>
        </BasicLayout>
      </PrivateRoute>

      <PublicRoute path="/password">
        <UserLayout>
          <Switch>
            <Route path="/password/forgot" exact>
              <ForgotPasswordPage />
            </Route>

            <Route path="/password/reset" exact>
              <ResetPasswordPage />
            </Route>
          </Switch>
        </UserLayout>
      </PublicRoute>

      <PublicRoute path="/user">
        <UserLayout>
          <Switch>
            <Route path="/user/verified" exact>
              <VerificationPage />
            </Route>
          </Switch>
        </UserLayout>
      </PublicRoute>

      <PublicRoute path="/" exact>
        <UserLayout>
          <AuthPage />
        </UserLayout>
      </PublicRoute>
    </Switch>
  )
}

new Promise(() => {
  checkAuthorization()
})
  .then(() => App())
  .catch((error) => console.error(error))

export default App
