import React from 'react'
import { Result } from 'antd'
// import { useTranslation } from 'react-i18next'

const ErrorPage = ({ error }) => {
  // const { t } = useTranslation(['common'])

  const errorMessage = () => {
    // if (error) {
    //   return error.status === 429 ? error.data : t('error.title')
    // }

    // return t('error.timeout')

    return error.message
  }

  return <Result status="warning" title={errorMessage()} />
}

export default ErrorPage
