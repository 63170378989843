import React from 'react'

import styles from './index.less'
import ResetPasswordForm from './components/ResetPasswordForm'

const ResetPasswordPage = () => {
  return (
    <div className={styles.main}>
      <ResetPasswordForm />
    </div>
  )
}

export default ResetPasswordPage
