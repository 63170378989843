import React, { useState } from 'react'
import { Checkbox, Button, Space, Form, Input, Row, Col, Spin, message, Modal, Typography } from 'antd'
import { useTranslation } from 'react-i18next'

import AssetService from '../../../../network/asset'
import MassConfirmation from './MassConfirmation'
import { InfoCircleFilled, InfoCircleOutlined } from '@ant-design/icons'
import { useToggle } from 'react-use'

import styles from '../../index.less'

const getWithdrawalInfo = (withdrawMethod) => {
  if (withdrawMethod.method === 'single') {
    return [
      {
        name: 'recipient',
        label: 'Recipient Address',
        value: withdrawMethod.recipient
      },
      {
        name: 'value',
        label: 'Value',
        value: withdrawMethod.value
      }
    ]
  }
  return []
}

const WithdrawalConfirmation = (props) => {
  const { form, withdrawMethod } = props
  const { t } = useTranslation(['withdraw'])
  const [isSendButtonDisabled, setIsSendButtonDisabled] = useState(false)
  const [isLoading, toggleLoading] = useToggle(false)
  const [hasError, setHasError] = useState(false)

  const sendCode = async () => {
    toggleLoading(true)
    try {
      await AssetService.sendCode()
      message.success(t('withdraw.sendCode.success'))
      setIsSendButtonDisabled(true)
      toggleLoading(false)
      setTimeout(() => setIsSendButtonDisabled(false), 60000)
    } catch (e) {
      toggleLoading(false)
      if (e.response.errorCode === 'WAIT_1_MINUTE') {
        return Modal.error(t('withdraw.sendCode.wait1Min'))
      }
      return Modal.error(t('withdraw.sendCode.wait1Min'))
    }
  }

  if (!withdrawMethod) return <Spin />
  const withdrawalInfo = getWithdrawalInfo(withdrawMethod)

  return (
    <div align="center" style={{ height: '100%' }}>
      {withdrawMethod.method === 'mass' ? (
        <MassConfirmation {...props} setHasError={setHasError} />
      ) : (
        <>
          {withdrawalInfo.map((item) => (
            <Form.Item key={item.name} name={item.name} label={item.label}>
              <Input id={`confirm_${item.name}`} value={item.value} disabled />
            </Form.Item>
          ))}

          <Form.Item name="txFee" label="Network Fee">
            <Input id="confirm_txFee" disabled />
          </Form.Item>
        </>
      )}

      {!hasError && (
        <>
          <Form.Item label="Verification Code">
            <Row gutter={[24, 12]}>
              <Col span={17} >
                <Form.Item name="code" noStyle rules={[{ required: true, min: 6, message: 'Please enter 6-digit verifiation code sent to your email.' }]}>
                  <Input
                    placeholder="6-digi code (Email)"
                    onChange={(e) =>
                      form.setFieldsValue({ code: e.target.value.replace(/\D/, '') })
                    }
                    maxLength={6}
                  />
                </Form.Item>
              </Col>
              <Col span={5} style={{paddingLeft:0}}>
                <Button
                  onClick={sendCode}
                  disabled={isSendButtonDisabled}
                  loading={isLoading}
                >
                  Send Code
                </Button>
              </Col>
              <Col span={2}></Col>
            </Row>
          </Form.Item>

          <pre className={styles.pre} style={{background:'#fffbe6'}}>
          <Space>
            <InfoCircleOutlined style={{ color: "#1890ff", fontSize: 15 }}/>
            <Typography align="left" style={{paddingLeft: 10}}>
            <Typography.Text type="secondary" style={{fontSize:'0.9em'}}>
              Please make sure you have selected the appropriate asset type and verify all
              withdrawal details before submitting.
            </Typography.Text>
            </Typography>
          </Space>
          </pre>

          <Form.Item
         wrapperCol={ {span: 24 }}
          name="agreement"
          valuePropName="checked"
          rules={[
            {
              validator: (_, value) =>
                value ? Promise.resolve() : Promise.reject(new Error('I have checked all the withdrawal details and acknowledge the risks associated with my withdrawal.')),
            },
          ]}
        >
          <Checkbox >I have checked the withdrawal details and fully understand Multichain's <a href="https://docs.multichain.info/legal/risks" target="_blank">Risk Warning</a>.</Checkbox>
        </Form.Item>
        </>
      )}
    </div>
  )
}

export default WithdrawalConfirmation
