import React from 'react'
import { Result, Button } from 'antd'
import { Link } from 'react-router-dom'

import { useQuery } from '../../utility'

const VerificationPage = () => {
  let query = useQuery()
  let email = query.get('e')
  let statusCode = query.get('m').toLowerCase()

  let status =
    statusCode === 'success'
      ? 'success'
      : statusCode === 'invalid'
      ? 'error'
      : statusCode === 'already_verified'
      ? 'success'
      : 'warning'

  let message =
    statusCode === 'success'
      ? 'Your account is now verified'
      : statusCode === 'invalid'
      ? 'There is something wrong with the verification, please try again or contact customer support'
      : statusCode === 'already_verified'
      ? 'Your account is already verified'
      : 'There is something wrong with the verification, please try again or contact customer support'

  return (
    <div>
      <Result
        status={status}
        title={message}
        subTitle={`${email}`}
        extra={
          statusCode === 'success' || statusCode === 'already_verified'
            ? [
                <Link to="/">
                  <Button type="primary" size="large" style={{borderRadius: 20}}>Login Now</Button>
                </Link>
              ]
            : []
        }
      />
    </div>
  )
}

export default VerificationPage
