import React, { useState, useMemo } from 'react'
import { Spin, Typography, Tabs } from 'antd'
import useSWR from 'swr'

import styles from '../index.less'
import MasterWallet from './MasterWallet'
import SubWalletAddresses from './SubWalletAddresses'
import DepositHistories from './DepositHistories'

import ProjectService from '../../../network/project'
import { serialize } from '../../../network/request'

// import DepositHistories from './components/DepositHistories'
var countDecimals = function (value) {
  if ((value % 1) != 0)
    return value.toString().split(".")[1].length;
  return 0;
};

const { TabPane } = Tabs

const AssetTabs = ({ project, asset }) => {
  const [currentTab, setCurrentTab] = useState('masterwallet')

  const { data: response, error } = useSWR(
    serialize(ProjectService.getAsset, {
      project,
      assetUid: asset
    })
  )
  if (error) {
    return <Typography.Text>{error.response}</Typography.Text>
  }

  if (!response) {
    return <Spin />
  }

  const assetDetail = response.messageBody

  return (
    <Tabs
      type="card"
      className={styles.verticalTabs}
      activeKey={currentTab}
      onChange={(key) => setCurrentTab(key)}
      moreIcon={null}
    >
      <TabPane
        tab={
          <div>
            <Typography.Title level={5} style={{ color: 'inherit' }}>
              Total Balance
            </Typography.Title>
            <Typography.Text type="secondary">
              {`${(countDecimals(assetDetail.balance.base) > 6) && (parseFloat(assetDetail.balance.base).toFixed(assetDetail.decimal) !== assetDetail.balance.base) ? assetDetail.balance.base : ""}`}
            </Typography.Text>
            <Typography.Title level={3} style={{ marginTop: 0, marginBottom: 0 }}>
              {`${parseFloat(assetDetail.balance.base).toFixed((countDecimals(assetDetail.balance.base) < 6) ? countDecimals(assetDetail.balance.base) : (assetDetail.decimal > 6 ? 6 : assetDetail.decimal))} ${assetDetail.symbol}`}
            </Typography.Title>
            <Typography.Text type="secondary">
              {Number(assetDetail.balance.usd) == 0 ? "" : `~$${Number(assetDetail.balance.usd).toFixed(2)}`}
            </Typography.Text>
          </div>
        }
        key="masterwallet"
      >
        <MasterWallet assetDetail={assetDetail} />
      </TabPane>

      <TabPane
        tab={
          <div>
            <Typography.Title level={5} style={{ color: 'inherit' }}>
              Total Subwallets
            </Typography.Title>
            <Typography.Title level={3} style={{ marginTop: 12, marginBottom: 0 }}>
              {assetDetail.subwalletCount}
            </Typography.Title>
            <Typography.Text type="secondary">Created</Typography.Text>
          </div>
        }
        key="subwallet_addresses"
      >
        <SubWalletAddresses
          project={project}
          asset={asset}
          assetDetail={assetDetail}
          setCurrentTab={setCurrentTab}
        />
      </TabPane>

      <TabPane
        tab={
          <div>
            <Typography.Title level={5} style={{ color: 'inherit' }}>
              Subwallet Deposits
            </Typography.Title>
            <Typography.Title level={3} style={{ marginTop: 12, marginBottom: 0 }}>
              {assetDetail.deposits.count}
            </Typography.Title>
            <Typography.Text type="secondary">Transactions</Typography.Text>
          </div>
        }
        key="deposit_histories"
      >
        <DepositHistories project={project} asset={asset} assetDetail={assetDetail} />
      </TabPane>
    </Tabs>
  )
}

export default AssetTabs
