import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LockOutlined, UserOutlined } from '@ant-design/icons'
import { Checkbox, Typography, Card, Button, Form, Input, message } from 'antd'

import styles from '../index.less'
import AuthService from '../../../network/auth'

const SignUpForm = () => {
  const { t } = useTranslation(['login', 'common'])

  const [submitting, setSubmitting] = useState(false)
  const [form] = Form.useForm()

  const handleSubmit = async (values) => {
    setSubmitting(true)

    try {
      const response = await AuthService.register({
        email: values.email,
        password: values.password
      })
      console.log(response)

      form.resetFields()
      setSubmitting(false)
      message.success('Registered successfully, an email confirmation had been sent to your inbox.')
    } catch (error) {
      console.log(error)
      setSubmitting(false)
      if (error.message == "ALREADY_REGISTERED")
        message.error(`Account already exist.`)
      if (error.message == "FAILED_PASSWORD_VALIDATE")
        message.error(`Please provide an alphanumeric passphrase with a minimum length of 8.`)
      if (error.message == "FAILED_TO_SEND_EMAIL")
        message.error(`Please provide an a valid email address.`)
    }
  }

  return (
    <Card className={styles.authCard}>
      <div style={{ marginBottom: 20 }}>
        <Typography.Text type="secondary">Welcome to Multichain, start using our API by creating an account.</Typography.Text>
      </div>
      <Form form={form} onFinish={handleSubmit}>
        <Form.Item
          className={styles.authFormItem}
          name="email"
          rules={[
            {
              required: true,
              message: t('login:email.required')
            }
          ]}
        >
          <Input
            className={styles.authInputForm}
            size="large"
            prefix={<UserOutlined className={styles.prefixIcon} />}
            placeholder={t('login:email.placeholder')}
          />
        </Form.Item>

        <Form.Item
          className={styles.authFormItem}
          name="password"
          rules={[
            {
              required: true,
              message: t('login:password.required')
            }
          ]}
        >
          <Input.Password
            className={styles.authInputForm} s
            size="large"
            prefix={<LockOutlined className={styles.prefixIcon} />}
            placeholder={t('login:password.placeholder')}
          />
        </Form.Item>

        <Form.Item
          className={styles.authFormItem}
          name="confirm_password"
          dependencies={['password']}
          rules={[
            {
              required: true,
              message: 'Please confirm your password!'
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve()
                }
                return Promise.reject(new Error('The two passwords that you entered do not match!'))
              }
            })
          ]}
        >
          <Input.Password
            className={styles.authInputForm}
            size="large"
            prefix={<LockOutlined className={styles.prefixIcon} />}
            placeholder={t('login:password.confirm')}
          />
        </Form.Item>

        <Form.Item
          className={styles.authFormItem}
          name="agreement"
          valuePropName="checked"
          rules={[
            {
              validator: (_, value) =>
                value ? Promise.resolve() : Promise.reject(new Error('I am over 18 age, and I agree to Multichain\'s Terms')),
            },
          ]}
        >
          <Checkbox >I have read and agree to Multichain's <a href="https://docs.multichain.info/legal/terms-of-service" target="_blank">Terms of Service</a></Checkbox>
        </Form.Item>

        <Button
          loading={submitting}
          size="large"
          key="done"
          type="primary"
          htmlType="submit"
          style={{ width: '100%', borderRadius: 20, marginTop: 0 }}
        >
          Create Account
        </Button>
      </Form>
    </Card>
  )
}

export default SignUpForm
