import React from 'react'
import { Space, Layout, Drawer, Button, Menu, Breadcrumb, Typography, Alert } from 'antd'
import { MoreOutlined } from '@ant-design/icons'
import { Link, useHistory } from 'react-router-dom'
import useBreadcrumbs from 'use-react-router-breadcrumbs'
import { useToggle } from 'react-use'
import { useSnapshot } from 'valtio'
import moment from 'moment'
import _ from 'lodash'

import styles from './BasicLayout.less'
import Avatar from '../components/GlobalHeader/AvatarDropdown'

import authStore, { logout } from '../store/auth'
import appStore from '../store/app'

const { Header, Content, Footer } = Layout

const routes = [{ path: '/account/change-password', breadcrumb: 'Change Password' }]

const BasicLayout = ({ children }) => {
  const { message } = useSnapshot(authStore)
  const { announcements } = useSnapshot(appStore)

  const history = useHistory()
  // ToDo: customize breadcrumb if needed
  const breadcrumbs = useBreadcrumbs(routes)
  const handleMenu = ({ key }) => {
    if (key === 'logout') {
      logout()
      return
    }

    // do nothing
    if (key === 'account') {
      return
    }

    if (key === 'contact') {
      return
    }

    if (key === 'docs') {
      return
    }

    toggleDrawer(false)
    history.push(key)
  }
  const [isDrawerOpen, toggleDrawer] = useToggle(false)

  const Menus = () => {
    return (
      <Menu theme="light" mode="horizontal" defaultSelectedKeys={['2']} style={{ borderBottom: 0 }} onClick={handleMenu}>
        {/* ToDo: loop route here */}
        <Menu.Item key="dashboard">Dashboard</Menu.Item>
        {/* <Menu.Item key="projects">Projects</Menu.Item> */}
        <Menu.Item key="assets">Assets</Menu.Item>
        <Menu.Item key="docs">
          <a href="https://docs.multichain.info" target="_blank" rel="noreferrer">
            Docs
          </a>
        </Menu.Item>
        <Menu.Item key="contact">
          <a href="mailto: support@multichain.info" target="_blank" rel="noreferrer">
            Contact
          </a>
        </Menu.Item>
      </Menu>
    )
  }

  const MobileMenus = () => {
    return (
      <Menu theme="light" mode="vertical" defaultSelectedKeys={['2']} onClick={handleMenu}>
        {/* ToDo: loop route here */}
        <Menu.Item key="dashboard">Dashboard</Menu.Item>
        {/* <Menu.Item key="projects">Projects</Menu.Item> */}
        <Menu.Item key="assets">Assets</Menu.Item>
        <Menu.Item key="docs">Docs</Menu.Item>
        <Menu.Item key="contact">Contact</Menu.Item>
        <Menu.Item key="account">Account</Menu.Item>
        <Menu.Divider />
        <Menu.Item key="logout" danger>
          Logout
        </Menu.Item>
      </Menu>
    )
  }

  return (
    <Layout className="layout">
      <Header className={styles.header}>
        <div className={styles.logo}>
          <img alt="logo" src="/logo.png" />
        </div>

        <div className={styles.left}>
          <Menus />
        </div>

        <div className={styles.right}>
          <Avatar />
        </div>

        <div className={styles.drawer}>
          <Button>
            <MoreOutlined onClick={() => toggleDrawer(true)} />
          </Button>
        </div>
      </Header>

      <Content className={styles.container}>
        {announcements.map((announcement) => {
          return (
            <Alert
              message={
                <div style={{ padding: 5 }}>
                  <Space direction="vertical">
                    <Space>
                      <Typography.Title level={5} style={{ margin: 0 }}>
                        {announcement.title}
                      </Typography.Title>
                      <Typography.Text>
                        ({moment.unix(announcement.updatedAt).format("MM/DD/YYYY hh:mm a").toLocaleString()})
                      </Typography.Text>
                    </Space>
                    <Typography.Text>{announcement.body}</Typography.Text>
                  </Space>
                </div>
              }
              closable
              style={{ marginTop: 16, borderRadius: 20, border: 0, background: "#e6f7ff", boxShadow: "5px 8px 24px 5px rgba(208, 216, 243, 0.6)" }}
            />
          )
        })}

        <br />

        <div className="site-layout-content">{children}</div>
      </Content>

      <Footer style={{ textAlign: 'center' }}>Multichain Wallet © Copyright 2021
        <p style={{ fontSize: 12 }} align="center"><a href="https://docs.multichain.info/legal/privacy-policy" target="_blank"><u>Privacy Policy</u></a> • <a href="https://docs.multichain.info/legal/terms-of-service" target="_blank"><u>Terms and Conditions</u></a> • <a href="https://docs.multichain.info/legal/risks" target="_blank"><u>Risk Warning</u></a></p></Footer>
      {message != null && (
        <div style={{ position: 'fixed', bottom: 16, right: 16 }}>
          <a href={message.url} target="_blank" rel="noreferrer">
            <Button
              shape="round"
              style={{
                backgroundColor: message.bgColor,
                color: 'white',
                border: 0,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                paddingTop: 20,
                paddingBottom: 20,
                boxShadow: "5px 8px 24px 5px rgba(208, 216, 243, 0.6)"
              }}
            >
              Message Us
              <img src={require('../discord_icon.svg').default} alt="discord" height="20" style={{ marginLeft: 12 }} />
            </Button>
          </a>
        </div>
      )}

      <Drawer
        placement="left"
        closable={false}
        onClose={() => toggleDrawer(false)}
        visible={isDrawerOpen}
      >
        <MobileMenus />
      </Drawer>
    </Layout>
  )
}

export default BasicLayout
