import client from './request'

const get = '/user/project'

const getAsset = `/user/project/asset`

const create = (data) => {
  return client.post('/user/project/create', data)
}

const updateLabel = (data) => {
  return client.post('/user/project/label', data)
}

const updateWebhook = (data) => {
  return client.post('/user/project/webhook', data)
}

const updateStatus = (data) => {
  return client.post('/user/project/toggle', data)
}

const testWebhook = (data) => {
  return client.post('/user/test/webhook', data)
}

const resendWebhook = (data) => {
  return client.post('/user/webhook', data)
}

const ProjectService = {
  get,
  getAsset,
  create,
  updateLabel,
  updateWebhook,
  updateStatus,
  testWebhook,
  resendWebhook
}

export default ProjectService
