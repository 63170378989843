import React from 'react'

import styles from './index.less'
import ForgotPasswordForm from './components/ForgotPasswordForm'

const ForgotPasswordPage = () => {
  return (
    <div className={styles.main}>
      <ForgotPasswordForm />
    </div>
  )
}

export default ForgotPasswordPage
