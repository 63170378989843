import { proxy } from 'valtio'
import { message } from 'antd'

const store = proxy({
  token: null,
  profile: null,
  message: null
})

export const checkAuthorization = () => {
  const token = getToken()
  const profile = getProfile()
  const message = getMessage()
  if (token != null) {
    login({ token, profile, message })
    return true
  } else {
    console.log('not authenticated')
    return false
  }
}

export const login = ({ token, profile, message }) => {
  if (token) {
    store.token = token
    store.profile = profile
    store.message = message

    localStorage.setItem('token', token)
    localStorage.setItem('profile', JSON.stringify(profile))
    localStorage.setItem('message', JSON.stringify(message))
  } else {
    // login error
  }
}

export const logout = (isExpired) => {
  clearToken()
  clearProfile()
  clearMessage()
  store.token = null
  store.profile = null
  store.message = null

  const key = 'updatable';
  if (isExpired) {
    message.loading({ content: 'Session expired, logging out...', key });
    setTimeout(() => {
      message.success({ content: 'Logout successful!', key, duration: 4 });
    }, 2000);
  } else {
    message.success({ content: 'Logout successful!', duration: 4 });
  }
}


export function getToken() {
  try {
    const token = localStorage.getItem('token')
    return token
  } catch (err) {
    clearToken()
    return null
  }
}

export function getProfile() {
  try {
    const profile = localStorage.getItem('profile')
    return JSON.parse(profile)
  } catch (err) {
    clearProfile()
    return null
  }
}

export function getMessage() {
  try {
    const message = localStorage.getItem('message')
    return JSON.parse(message)
  } catch (err) {
    clearMessage()
    return null
  }
}

export function clearToken() {
  localStorage.removeItem('token')
}

export function clearProfile() {
  localStorage.removeItem('profile')
}

export function clearMessage() {
  localStorage.removeItem('message')
}

export default store
