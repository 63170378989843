import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { LockFilled, LockOutlined, UserOutlined } from '@ant-design/icons'
import { Divider, Tag, Typography, Card, Button, Form, Input, message } from 'antd'

import styles from '../index.less'
import AuthService from '../../../network/auth'
import { login } from '../../../store/auth'

const SignInForm = () => {
  const { t } = useTranslation(['login', 'common'])

  const [submitting, setSubmitting] = useState(false)
  const [form] = Form.useForm()

  const handleSubmit = async (values) => {
    setSubmitting(true)

    try {
      const response = await AuthService.login(values)
      console.log(response)

      login({
        token: response.data.messageBody.access_token,
        profile: response.data.messageBody.user,
        message: response.data.messageBody.messageUs
        // ToDo: add projects? add expiry?
      })
    } catch (error) {
      console.log(error)
      setSubmitting(false)

      if (error.message === "USER_NOT_VERIFIED") {
        message.error('Please verify your email ')
      } else if (error.message === "ERR_INVALID_OR_DISABLED_ACC") {
        message.error("Account not found or is disabled.")
      } else if (error.message === "ERR_INCORRECT_PASSWORD") {
        message.error("Incorrect email or password.")
      } else {
        console.log(error.message)
        message.error("Something went wrong, please try again later or contact support.")
      }
    }
  }

  return (
    <Card className={styles.authCard}>
      <div style={{ marginBottom: 20 }}>
        <div style={{ textAlign: 'center' }}>
          <Typography.Text type="secondary">URL verification:</Typography.Text>
          <Tag color="green"><LockFilled />{" "}<strong>https://</strong>secure.multichain.info</Tag>
        </div>
        <Divider style={{ margin: "15px 0" }} />
        <Typography.Text type="secondary">You could always message us on discord if you have any question.</Typography.Text>
      </div>
      <Form form={form} onFinish={handleSubmit}>
        <Form.Item
          className={styles.authFormItem}
          name="email"
          rules={[
            {
              required: true,
              message: t('login:email.required')
            }
          ]}
        >
          <Input
            size="large"
            className={styles.authInputForm}
            prefix={<UserOutlined className={styles.prefixIcon} />}
            placeholder={t('login:email.placeholder')}
          />
        </Form.Item>

        <Form.Item
          className={styles.authFormItem}
          name="password"
          rules={[
            {
              required: true,
              message: t('login:password.required')
            }
          ]}
        >
          <Input.Password
            size="large"
            className={styles.authInputForm}
            prefix={<LockOutlined className={styles.prefixIcon} />}
            placeholder={t('login:password.placeholder')}
          />
        </Form.Item>

        <div
          style={{
            marginBottom: 24
          }}
        >
          <Link
            to="/password/forgot"
            style={{
              float: 'right'
            }}
          >
            <p><u>{t('login:forgotPassword')}</u></p>
          </Link>
        </div>

        <Button
          loading={submitting}
          size="large"
          key="done"
          type="primary"
          htmlType="submit"
          style={{ width: '100%', borderRadius: 20 }}
        >
          Log In
        </Button>
      </Form>
    </Card>
  )
}

export default SignInForm
