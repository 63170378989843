import client from './request'

const register = (data) => {
  return client.post('/auth/register', data)
}

const login = (data) => {
  return client.post('/auth/login', data)
}

const changePassword = (data) => {
  return client.post(`/auth/password/change`, data)
}

const resetPassword = (data) => {
  return client.post(`/auth/password/reset`, data)
}

const changePasswordFromUser = (data) => {
  return client.post(`/user/password`, data)
}

const sendEmailConfirmation = (data) => {
  return client.post(`/auth/resend`, data)
}

const AuthService = {
  login,
  changePassword,
  register,
  resetPassword,
  changePasswordFromUser,
  sendEmailConfirmation
}

export default AuthService
