import { proxy } from 'valtio'

const assetStore = proxy({
  filterType: 'hash',
  filter: null,
  search: null,
  list: []
})

export default assetStore
