import React, { useState } from 'react'
import { useMemo } from 'react'
import { Empty, Tooltip, Typography, Row, Tag, Col, Card, Input, Pagination } from 'antd'
import { useSnapshot } from 'valtio'
import useSWR from 'swr'

import styles from '../index.less'
import SubwalletDepositHistoriesList from './Lists/SubwalletDepositHistoriesList'
import subwalletStore from '../../../store/subwallet'
import assetStore from '../../../store/asset'
import { serialize } from '../../../network/request'
import SubWalletService from '../../../network/subwallet'
import { LinkOutlined } from '@ant-design/icons'

const SubWalletAddresses = (props) => {
  const { assetDetail } = props
  const { search } = useSnapshot(subwalletStore)
  const { list } = useSnapshot(assetStore)
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize] = useState(10)

  const selectedAsset = useMemo(() => {
    return list.find((element) => {
      return element.uid === assetDetail.assetUid
    })
  }, [assetDetail.assetUid, list])

  const { data: response } = useSWR(
    serialize(SubWalletService.get, {
      page: 1,
      limit: pageSize,
      project: assetDetail.project,
      assetUid: assetDetail.assetUid,
      address: search
    })
  )

  const onPageChange = (page) => {
    setCurrentPage(page)
  }

  const { results, meta } = response?.messageBody ?? {}
  return (
    <Card style={{ borderRadius: 20 }} className={styles.assetCard}>
      <Row gutter={[24, 12]}>
        {results?.length > 0 ? (<>
        <Col md={8} xs={24}>
          <Row gutter={[24, 8]}>
            <Col span={24} xs={{ span: 24, order: 1 }}>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: 12 }}>
                {selectedAsset != null && (
                  <img
                    src={selectedAsset.logoUrl}
                    alt={assetDetail.symbol}
                    width="30"
                    height="30"
                  />
                )}
                <Typography.Title level={4} style={{ margin: '0 5px' }}>
                  {selectedAsset.symbol} ({selectedAsset.type}) Subwallets
                </Typography.Title>
                {assetDetail.isWithdrawable ? <Tooltip title="Asset is active"><Tag style={{ fontSize: 15, borderRadius: 15, padding: "3px 15px 3px 15px" }} color="green">Online</Tag></Tooltip>
                  :
                  <Tooltip title="Withdrawal is temporarily disabled"><Tag style={{ fontSize: 15, borderRadius: 15, padding: "3px 15px 3px 15px" }} color="red">Disabled</Tag></Tooltip>}
              </div>
            </Col>
          </Row>
        </Col>
          <Col md={16} xs={24}>
            <Row gutter={[24, 8]}>
              <Col span={20} xs={{ span: 24, order: 2 }} align="right">
                <Input.Search
                  placeholder="Search by subwallet address"
                  allowClear
                  onSearch={(value) => {
                    subwalletStore.search = value
                  }}
                  onChange={(e) => {
                    subwalletStore.search = e.target.value
                  }}
                  className={styles.search}
                  value={search}
                />
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <>
              <SubwalletDepositHistoriesList
                {...props}
                currentPage={currentPage}
                pageSize={pageSize}
              />

              <div style={{ display: 'none' }}>
                {currentPage <= meta?.subwalletCount / pageSize && (
                  <SubwalletDepositHistoriesList
                    {...props}
                    currentPage={currentPage + 1}
                    pageSize={pageSize}
                  />
                )}
              </div>

              {results?.length > 0 && (
                <div align="center">
                  <Pagination
                    onChange={onPageChange}
                    defaultCurrent={1}
                    total={meta?.subwalletCount}
                    pageSize={pageSize}
                  />
                </div>
              )}
            </>
          </Col></>
        ) : (
          <><Col md={8} xs={24}>
          <Row gutter={[24, 8]}>
            <Col span={24} xs={{ span: 24, order: 1 }}>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: 12 }}>
                {selectedAsset != null && (
                  <img
                    src={selectedAsset.logoUrl}
                    alt={assetDetail.symbol}
                    width="30"
                    height="30"
                  />
                )}
                <Typography.Title level={4} style={{ margin: '0 5px' }}>
                  {selectedAsset.symbol} ({selectedAsset.type}) Subwallets
                </Typography.Title>
                {assetDetail.isWithdrawable ? <Tooltip title="Asset is active"><Tag style={{ fontSize: 15, borderRadius: 15, padding: "3px 15px 3px 15px" }} color="green">Online</Tag></Tooltip>
                  :
                  <Tooltip title="Withdrawal is temporarily disabled"><Tag style={{ fontSize: 15, borderRadius: 15, padding: "3px 15px 3px 15px" }} color="red">Disabled</Tag></Tooltip>}
              </div>
            </Col>
          </Row>
        </Col>
          <Col md={16} xs={24}>
            <Row gutter={[24, 8]}>
              <Col span={20} xs={{ span: 24, order: 2 }} align="right">
                <Input.Search
                  placeholder="Search by subwallet address"
                  allowClear
                  onSearch={(value) => {
                    subwalletStore.search = value
                  }}
                  onChange={(e) => {
                    subwalletStore.search = e.target.value
                  }}
                  className={styles.search}
                  value={search}
                />
              </Col>
            </Row>
          </Col><Col span={20} xs={{ span: 24 }} align="center"> <div style={{ padding: 50 }}>
            <Empty
            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
            imageStyle={{
              height: 100,
            }}
            description={
              <span>
                Start generating subwallets <a href="https://docs.multichain.info/start/api/private-api" target="_blank">(Create Subwallets API)</a> to begin receiving crypto deposits or to host blockchain wallets.
              </span>
            }
            style={{ maxWidth: 500 }}
          >
          </Empty>
          </div>
          </Col></>
        )}
      </Row>

      {/* <Modal
        title="Deposit Histories"
        visible={isModalVisible}
        footer={null}
        onCancel={() => {
          setSelectedDeposit(null)
        }}
        width={1000}
        destroyOnClose={true}
        centered
      >
        <SubwalletDepositHistoriesList
          assetSymbol={assetDetail.symbol}
          address={selectedDeposit}
          assetUid={asset}
          project={project}
        />
      </Modal> */}
    </Card>
  )
}

export default SubWalletAddresses
