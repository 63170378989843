import React, { useMemo, useState } from 'react'
import { Input, Pagination, Row, Col } from 'antd'
import useSWR from 'swr'
import { useSnapshot } from 'valtio'

import { serialize } from '../../../../network/request'
import MasterWalletService from '../../../../network/masterwallet'
import MasterWalletTransactionsList from './MasterWalletTransactionsList'
import masterwalletStore from '../../../../store/masterwallet'

const MasterWalletTransactions = (props) => {
  const { search } = useSnapshot(masterwalletStore)
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize] = useState(7)
  const { type, assetDetail, shouldMutate } = props

  const url = useMemo(() => {
    if (type === 'deposits') {
      return MasterWalletService.getDeposits
    } else {
      return MasterWalletService.getWithdrawals
    }
  }, [type])
  const config = useMemo(
    () => ({
      page: 1,
      limit: pageSize,
      type,
      project: assetDetail.project,
      assetUid: assetDetail.assetUid,
      hash: search
    }),
    // use shouldMutate to force revalidate
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pageSize, shouldMutate, search]
  )
  const { data: response } = useSWR(serialize(url, config))
  const { transactions, meta } = response?.messageBody ?? {}

  const onPageChange = (page) => {
    setCurrentPage(page)
  }

  return (
    <div>
      <Row gutter={[24, 8]}>
      <Col lg={{ span: 3, order: 1 }} align="left"></Col>
      <Col lg={{ span: 18, order: 2 }} xs={{ span: 24, order: 1 }} align="right">
      <Input.Search
        placeholder="Search by Txid"
        disabled={transactions?.length > 0 ? false : true}
        allowClear
        onSearch={(value) => {
          masterwalletStore.search = value
        }}
        onChange={(e) => {
          masterwalletStore.search = e.target.value
        }}
        value={search}
      />
      </Col>
      <Col lg={{ span: 3, order: 3 }} align="left"></Col>
      </Row>
      
      <MasterWalletTransactionsList {...props} currentPage={currentPage} pageSize={pageSize} />

      {/* cache for next page */}
      <div style={{ display: 'none' }}>
        {currentPage <= meta?.transactionCount / pageSize && (
          <MasterWalletTransactionsList
            {...props}
            currentPage={currentPage + 1}
            pageSize={pageSize}
          />
        )}
      </div>

      {transactions?.length > 0 && (
        <div align="center">
          <Pagination
            onChange={onPageChange}
            defaultCurrent={1}
            total={meta?.transactionCount}
            pageSize={pageSize}
            showSizeChanger={false}
            showTotal={total => `Total ${meta?.transactionCount} transaction`}
          />
        </div>
      )}
    </div>
  )
}

export default MasterWalletTransactions
